import { helperFunctions } from "../helpers/helperFunctions";
import { deleteReplyFiled, deleteReplyStart, deleteReplySuccess } from "../slices/deleteSlices/deleteReplySlice";
import { addEditReplyFiled, addEditReplyStart, addEditReplySuccess } from "../slices/formsSlices/addEditReplyFormSlice";
import { gettingRepliesFiled, gettingRepliesStart, gettingRepliesSuccess } from "../slices/repliesSlice";
import { axiosService } from "./axios.service";

async function getAll(dispatch, pageNumber) {
  try {
    let dataRes = []
    dispatch(gettingRepliesStart());
    const res = await axiosService.get(`replies/getAll?page=${pageNumber}`);
    if (res.status === 200) {
      res.data.data.data.forEach(element => {
        const reply = {
          id: element.id,
          words: element?.words,
          type: element.type,
          message: JSON.parse(element.message),
          createdAt: element?.created_at ? helperFunctions.formatDate(element?.created_at) : null
        }
        dataRes.push(reply);
      });
      const result = {
        success: true,
        currentPage: res.data.data.current_page,
        itemsCount: res.data.data.total,
      };

      dispatch(gettingRepliesSuccess(dataRes))
      return result
    } else {
      const result = {
        success: false,
        currentPage: null,
        itemsCount: null,
      };

      dispatch(gettingRepliesFiled(res.error.message))
      return result;
    }
  } catch (error) {
    const result = {
      success: false,
      currentPage: null,
      itemsCount: null,
    };
    dispatch(gettingRepliesFiled(error?.message || "An error occurred"))
    return result;
  }
};

async function add(dispatch, body) {
  try {
    dispatch(addEditReplyStart())
    const res = await axiosService.post('replies/add', body, true);
    if (res.status === 200) {
      dispatch(addEditReplySuccess());
      return true
    } else {
      dispatch(addEditReplyFiled(res.error.message))
      return false
    }
  } catch (error) {
    dispatch(addEditReplyFiled());
    return false
  }
};

const edit = async (dispatch, body, replyId) => {
  try {
    dispatch(addEditReplyStart())
    const res = await axiosService.post(`replies/edit?id=${replyId}`, body);
    if (res.status === 200) {
      dispatch(addEditReplySuccess());
      return true
    } else {
      dispatch(addEditReplyFiled(res.error.message))
      return false
    }
  } catch {
    dispatch(addEditReplyFiled());
    return false
  }
};

async function remove(dispatch, reply) {
  try {
    dispatch(deleteReplyStart(reply))
    const res = await axiosService.get(`replies/delete?id=${reply}`);
    if (res.status === 200) {
      dispatch(deleteReplySuccess());
      return true
    } else {
      dispatch(deleteReplyFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(deleteReplyFiled());
    return false
  }
};

const getByMessage = async (message) => {
  const res = await axiosService.get(`replies/get?message=${message}`);
  return res;
};


export const repliesService = {
  getAll, add, edit, remove, getByMessage
}