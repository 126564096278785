import { createSlice } from '@reduxjs/toolkit';

const clientsSlice = createSlice({
    name: 'clients',
    initialState: {
        isLoadingClients: false,
        clients: [],
        clientsError: null
    },
    reducers: {
        gettingClientsStart: (state) => {
            state.isLoadingClients = true;
            state.clients = [];
            state.clientsError = null;
        },
        gettingClientsSuccess: (state, actions) => {
            state.isLoadingClients = false;
            state.clients = actions?.payload || [];
            state.clientsError = null;
        },
        gettingClientsFiled: (state, actions) => {
            state.isLoadingClients = false;
            state.clients = [];
            state.clientsError = actions.payload;
        }
    },
});

export const { gettingClientsStart, gettingClientsSuccess, gettingClientsFiled } = clientsSlice.actions;
export default clientsSlice.reducer;
