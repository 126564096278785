/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Styles } from './styles';
import Avatar from "../../../assets/images/avatar.jpg";
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { authService } from '../../../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../slices/authSlice';
import SpinnerLoading from '../../../components/loading/spinnerLoading/SpinnerLoading';
import environment from '../../../environment';
import { axiosService } from '../../../services/axios.service';
import { useNavigate } from 'react-router';
import MainButton from '../../../components/buttons/mainButton/MainButton';
import Sidebar from '../../../components/sideBarComponent/SideBar';


const DefaultLayout = ({ children, title }) => {
    const { token, isLoggedIn } = useSelector((state) => state.auth);
    const { profile, isLoadingProfile, profileError } = useSelector((state) => state.profile);
    const { logoutError, isLoadingLogout } = useSelector((state) => state.logout);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            axiosService.setToken(token)
            if (profile == null) {
                authService.getProfile(dispatch)
            }
        } else {
            navigate('/login')
        }
    }, [isLoggedIn, profile, token]);



    return (
        <Styles>
            {isLoadingProfile || isLoadingLogout ? (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    <SpinnerLoading />
                </div>
            ) : profileError || logoutError ? (
                <div className="vh-100 d-flex justify-content-center align-items-center d-flex flex-column">
                    <p className="mb-3 text-danger">{profileError || logoutError}</p>
                    <MainButton type="button" text={"اعادة المحاولة"} onClick={async () => await authService.getProfile(dispatch)} />
                </div>) : (
                <div className="main-page">
                    <Sidebar />
                    <div className="main-data">
                        <Navbar expand="lg">
                            <Container>
                                <Nav>
                                    <Dropdown>
                                        <Dropdown.Toggle id="profile">
                                            {profile?.name}
                                            <div className="img-container">
                                                <img src={profile?.image == null ? Avatar : environment.API_LINK_STORAGE + profile?.image} alt="profile" />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className={"dropdown-menu-ar"}>
                                            {/* <Dropdown.Item href="/profile">الملف الشخصي</Dropdown.Item> */}
                                            <Dropdown.Item onClick={async () => await authService.logout(dispatch)}>تسجيل الخروج</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </Container>
                        </Navbar>
                        <div className="children-data">
                            <Container>
                                <h1 className='text-dark h3 my-4'>{title}</h1>
                                {children}
                            </Container>
                        </div>
                        <footer>
                            <Container>
                                copyright &nbsp;
                                <a target="_blank" href='https://www.facebook.com/akwad4it/' rel="noreferrer">
                                    Akwad &copy;
                                </a>
                                &nbsp;2023
                            </Container>
                        </footer>
                    </div>
                </div>
            )}
        </Styles>
    );
};

export default DefaultLayout;
