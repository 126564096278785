import React, { useEffect } from 'react'
import withGuard from '../../helpers/withGuard';
import { Container } from "react-bootstrap";
import { Styles } from "./styles";
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout';
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/clients')
    }, [])
    return (
        <DefaultLayout>
            <Styles>
                {/* <main> */}
                {/* <Container>
                        D
                    </Container>
                </main> */}
            </Styles>
        </DefaultLayout>
    );
}


export default withGuard(HomePage);