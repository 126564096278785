import { createSlice } from '@reduxjs/toolkit';
import environment from '../environment';
import { axiosService } from '../services/axios.service';

// Function to check if the user is already logged in
const isUserLoggedIn = () => {
    const token = localStorage.getItem(environment.TOKEN_KEY);
    return token ? true : false;
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: isUserLoggedIn(),
        token: localStorage.getItem(environment.TOKEN_KEY) || null,
        userType: null,
    },
    reducers: {
        setLoginInfo: (state, actions) => {
            localStorage.setItem(environment.TOKEN_KEY, actions.payload.token);
            state.isLoggedIn = true;
            state.token = actions.payload.token;
            state.userType = actions.payload.userType;
            axiosService.setToken(actions.payload.token);
        },
        removeLoginInfo: (state) => {
            localStorage.removeItem(environment.TOKEN_KEY);
            state.isLoggedIn = false;
            state.token = null;
            state.userType = null;
            axiosService.setToken(null);
        },
    },
});

export const { setLoginInfo, removeLoginInfo } = authSlice.actions;
export default authSlice.reducer;
