const config = {
  //API ENDPOINTS
  AUTH_ENDPOINT: "auth",
  USER_ENDPOINT: "user",

  //LOCAL STORAGE KEYS
  TOKEN_KEY: "token",
  REFRESH_TOKEN_KEY: "refreshToken",
  LANGUAGE_KEY: "lang",
  THEME_KEY: "theme",
  DIRECTION_KEY: "dir",
  CLAIMS_KEY: "claims",

  //CONSTANT
  API_URL: "https://admin-whatsapp.akwad4it.com/api/",
  API_LINK_STORAGE: "https://admin-whatsapp.akwad4it.com/",
  SOCKET_URL: "wss://whatsapp-node.akwad4it.com/ws?id=",
};

const environment = {
  ...config,
};

export default environment;
