/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import GroupMessagesSenderTypes from '../../enums/GroupMessagesSenderTypes';
import withGuard from '../../helpers/withGuard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'formik';
import { FloatingLabel, Form as BootstrapForm, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import * as formik from 'formik';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import MessageTypes from '../../enums/MessageTypes';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { messagesService } from '../../services/messages.service';
import { toasts } from '../../components/toasts';
import { handelErrorsService } from '../../services/handelErrors.service';
import { clientsCategoriesService } from '../../services/clientsCategories.service';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import { templatesService } from '../../services/templates.service';
import { gettingTemplatesFiled, gettingTemplatesStart, gettingTemplatesSuccess } from '../../slices/templatesSlice';
import { countriesService } from '../../services/countries.service';

const validationSchema = Yup.object().shape({
});


function SendGroupMessage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const [messageType, setMessageType] = useState(MessageTypes.text)
    const [isMessageTemplate, setIsMessageTemplate] = useState(false)
    const [groupMessagesSenderType, setGroupMessagesSenderType] = useState(GroupMessagesSenderTypes.allClients)
    const { isLoadingSendGroupMessage, sendGroupMessageError } = useSelector((state) => state.sendGroupMessageForm);
    const { categories, isLoading, error } = useSelector((state) => state.categories);
    const { templates, isLoadingTemplates, errorTemplates } = useSelector((state) => state.templates);
    const { isLoadingCountries, countries, countriesError } = useSelector((state) => state.countries);


    useEffect(() => {
        // if (isMessageTemplate && templates.length == 0)
        //     getTemplates();
        if (groupMessagesSenderType == GroupMessagesSenderTypes.byCountry && countries.length == 0)
            countriesService.getAll(dispatch)

        if (groupMessagesSenderType == GroupMessagesSenderTypes.byCategory && categories.length == 0)
            clientsCategoriesService.getAll(dispatch)
    }, [isMessageTemplate, groupMessagesSenderType])


    const handleSubmit = async (values, { setSubmitting }) => {
        const res = await messagesService.sendGroup(dispatch, values);
        if (res) {
            navigate('/group-messages');
            toasts.success(sendGroupMessageError);
        } else {
            toasts.error(sendGroupMessageError);
        }
        setSubmitting(false);
    };


    async function getTemplates() {
        try {
            dispatch(gettingTemplatesStart());
            const res = await templatesService.getAll();
            if (res.status === 200) {
                if (res.data.success) {
                    const dataRes = []
                    res.data.data.forEach(element => {
                        const template = {
                            id: element.id,
                            name: element.name,
                        }
                        dataRes.push(template);
                    });
                    dispatch(gettingTemplatesSuccess(dataRes))
                } else {
                    dispatch(gettingTemplatesFiled(res.error))
                    toasts.error(error)
                }
            } else {
                const errorRes = handelErrorsService.handel(res.status)
                dispatch(gettingTemplatesFiled(errorRes))
                toasts.error(error)
            }
        } catch (error) {
            dispatch(gettingTemplatesFiled())
            toasts.error()
        }
    }

    return (
        <DefaultLayout title={'ارسال رسالة جماعية'}>
            <Formik initialValues={{
                group_message_type: groupMessagesSenderType,
                type: messageType,
                body: '',
                description: '',
                latitude: 0,
                longitude: 0,
                media: null,
                template_id: null,
                category_id: null,
            }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >

                {({ handleChange, values, touched, errors, isSubmitting }) => (
                    <Form encType='multipart/form-data'>
                        <div className='row mb-3'>
                            <h5>لمن تريد ارسال الرسالة الجماعية؟</h5>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3" >
                                    <BootstrapForm.Check
                                        inline
                                        label="كل العملاء"
                                        name="group_message_type"
                                        defaultChecked
                                        type={type}
                                        value={GroupMessagesSenderTypes.allClients}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setGroupMessagesSenderType(event.target.value)
                                        }}
                                    />
                                    <BootstrapForm.Check
                                        inline
                                        label="ملف اكسل"
                                        name="group_message_type"
                                        type={type}
                                        value={GroupMessagesSenderTypes.fromFile}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setGroupMessagesSenderType(event.target.value)
                                        }}
                                    />
                                    <BootstrapForm.Check
                                        inline
                                        label="حسب التصنيف"
                                        name="group_message_type"
                                        type={type}
                                        value={GroupMessagesSenderTypes.byCategory}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setGroupMessagesSenderType(event.target.value)
                                        }}
                                    />
                                    <BootstrapForm.Check
                                        inline
                                        label="حسب البلدة"
                                        name="group_message_type"
                                        type={type}
                                        value={GroupMessagesSenderTypes.byCountry}
                                        onChange={(event) => {
                                            handleChange(event)
                                            setGroupMessagesSenderType(event.target.value)
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='row mb-3'>
                            <div className='col-lg-6'>
                                {parseInt(groupMessagesSenderType) === GroupMessagesSenderTypes.fromFile ?
                                    <FloatingLabel controlId="numbers_file" label="ملف الأرقام" className={'mb-3'}>
                                        <BootstrapForm.Control type="file" name="numbers_file"
                                            onChange={e => values.numbers_file = e.target.files[0]} isValid={touched.numbers_file && !errors.numbers_file} isInvalid={touched.numbers_file && errors.numbers_file} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.numbers_file}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                    : parseInt(groupMessagesSenderType) === GroupMessagesSenderTypes.byCategory ?
                                        isLoading ?
                                            <div className='d-flex justify-content-center align-items-center py-5'>
                                                <SpinnerLoading />
                                            </div> : error ?
                                                <div className='d-flex justify-content-center align-items-center py-5'>
                                                    <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await clientsCategoriesService.getAll(dispatch)} />
                                                </div>
                                                :
                                                categories.length > 0 ?
                                                    <FloatingLabel controlId="category_id" label="الصنف" className={'mb-3'}>
                                                        <BootstrapForm.Select onChange={handleChange} isValid={touched.category_id && !errors.category_id} isInvalid={touched.category_id && errors.category_id} >
                                                            {Array.from(categories).map((value, index) => (
                                                                <option value={value.id} key={index}>{value.name}</option>
                                                            ))}
                                                        </BootstrapForm.Select>
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.category_id}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                    : <Alert variant='danger'>لا يوجد أصناف</Alert> : parseInt(groupMessagesSenderType) === GroupMessagesSenderTypes.byCountry ?
                                            isLoadingCountries ?
                                                <div className='d-flex justify-content-center align-items-center py-5'>
                                                    <SpinnerLoading />
                                                </div> : countriesError ?
                                                    <div className='d-flex justify-content-center align-items-center py-5'>
                                                        <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await countriesService.getAll(dispatch)} />
                                                    </div>
                                                    :
                                                    countries.length > 0 ?
                                                        <FloatingLabel controlId="country_id" label="البلدة" className={'mb-3'}>
                                                            <BootstrapForm.Select onChange={handleChange} isValid={touched.country_id && !errors.country_id} isInvalid={touched.country_id && errors.country_id} >
                                                                {Array.from(countries).map((value, index) => (
                                                                    <option value={value.id} key={index}>{value.name}</option>
                                                                ))}
                                                            </BootstrapForm.Select>
                                                            <BootstrapForm.Control.Feedback type="invalid">
                                                                {errors.country_id}
                                                            </BootstrapForm.Control.Feedback>
                                                        </FloatingLabel>
                                                        : <Alert variant='danger'>لا يوجد بلدات</Alert> : ""}
                            </div>
                        </div>
                        {/* <div className='row mb-3'>
                            <BootstrapForm.Check
                                inline
                                label="هل رسالة قالب موجود مسبقا؟"
                                type="checkbox"
                                value={false}
                                onChange={() => {
                                    if (isMessageTemplate) {
                                        setIsMessageTemplate(false)
                                    }
                                    else {
                                        setIsMessageTemplate(true)
                                    }
                                }}
                            />
                        </div> */}
                        {
                            !isMessageTemplate ?
                                <>
                                    <div className='row mb-3'>
                                        <h5>يرجى تحديد نوع الرسالة المرسلة</h5>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3" >
                                                <BootstrapForm.Check
                                                    inline
                                                    label="رسالة نصية"
                                                    name="type"
                                                    defaultChecked
                                                    type={type}
                                                    value={MessageTypes.text}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        setMessageType(event.target.value)
                                                    }}
                                                />
                                                {/* <BootstrapForm.Check
                                                    inline
                                                    label="رسالة وسائط"
                                                    name="type"
                                                    type={type}
                                                    value={MessageTypes.media}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        setMessageType(event.target.value)
                                                    }}
                                                /> */}
                                                <BootstrapForm.Check
                                                    inline
                                                    label="رسالة موقع"
                                                    name="type"
                                                    type={type}
                                                    value={MessageTypes.location}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        setMessageType(event.target.value)
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='row mb-3'>
                                        <h5>يرجى ملء بيانات الرسالة</h5>

                                        {parseInt(messageType) === MessageTypes.text ?
                                            <div className='col-lg-6'>
                                                <FloatingLabel controlId="body" label="الرسالة" className={'mb-3'}>
                                                    <BootstrapForm.Control type="text" name="body" placeholder="مرحبا بك في شركة أمان " value={values.body}
                                                        onChange={handleChange} isValid={touched.body && !errors.body} isInvalid={touched.body && errors.body} />
                                                    <BootstrapForm.Control.Feedback type="invalid">
                                                        {errors.body}
                                                    </BootstrapForm.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                            : ''}

                                        {parseInt(messageType) === MessageTypes.media ?
                                            <>
                                                <div className='col-lg-6'>
                                                    <FloatingLabel controlId="body" label="الرسالة" className={'mb-3'}>
                                                        <BootstrapForm.Control type="text" name="body" placeholder="مرحبا بك في شركة أمان " value={values.body}
                                                            onChange={handleChange} isValid={touched.body && !errors.body} isInvalid={touched.body && errors.body} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.body}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <FloatingLabel controlId="media" label="ملف الوسائط" className={'mb-3'}>
                                                        <BootstrapForm.Control type="file" name="media"
                                                            onChange={e => values.media = e.target.files[0]} isValid={touched.media && !errors.media} isInvalid={touched.media && errors.media} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.media}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                            </>
                                            : ''}
                                        {parseInt(messageType) === MessageTypes.location ?
                                            <>
                                                <div className='col-lg-6'>
                                                    <FloatingLabel controlId="description" label="العنوان" className={'mb-3'}>
                                                        <BootstrapForm.Control type="text" name="description" placeholder="حمص الساعة جديدة" value={values.description}
                                                            onChange={handleChange} isValid={touched.description && !errors.description} isInvalid={touched.description && errors.description} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.description}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <FloatingLabel controlId="latitude" label="خط الطول" className={'mb-3'}>
                                                        <BootstrapForm.Control type="numbers" name="latitude" placeholder="حمص الساعة جديدة" value={values.latitude}
                                                            onChange={handleChange} isValid={touched.latitude && !errors.latitude} isInvalid={touched.latitude && errors.latitude} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.latitude}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <FloatingLabel controlId="longitude" label="خط العرض" className={'mb-3'}>
                                                        <BootstrapForm.Control type="numbers" name="longitude" placeholder="حمص الساعة جديدة" value={values.longitude}
                                                            onChange={handleChange} isValid={touched.longitude && !errors.longitude} isInvalid={touched.longitude && errors.longitude} />
                                                        <BootstrapForm.Control.Feedback type="invalid">
                                                            {errors.longitude}
                                                        </BootstrapForm.Control.Feedback>
                                                    </FloatingLabel>
                                                </div>
                                            </>
                                            : ''}
                                    </div>
                                </>
                                : ""}
                        <div className='col-lg-12 d-flex justify-content-end'>
                            <MainButton type="submit" disabled={isSubmitting} text={isLoadingSendGroupMessage ? <BtnLoading /> : "ارسال"} />
                        </div>
                    </Form>
                )}
            </Formik>

        </DefaultLayout>
    )
}


export default withGuard(SendGroupMessage)