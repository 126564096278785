import { createSlice } from '@reduxjs/toolkit';

const repliesSlice = createSlice({
    name: 'replies',
    initialState: {
        isLoadingReplies: false,
        replies: [],
        errorReplies: null
    },
    reducers: {
        gettingRepliesStart: (state) => {
            state.isLoadingReplies = true;
            state.replies = [];
            state.errorReplies = null;
        },
        gettingRepliesSuccess: (state, actions) => {
            state.isLoadingReplies = false;
            state.replies = actions.payload;
            state.errorReplies = null;
        },
        gettingRepliesFiled: (state, actions) => {
            state.isLoadingReplies = false;
            state.replies = [];
            state.errorReplies = actions.payload;
        }
    },
});

export const { gettingRepliesStart, gettingRepliesSuccess, gettingRepliesFiled } = repliesSlice.actions;
export default repliesSlice.reducer;
