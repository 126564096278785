import { createSlice } from '@reduxjs/toolkit';

const sendGroupMessageFormSlice = createSlice({
    name: 'sendGroupMessageForm',
    initialState: {
        isLoadingSendGroupMessage: false,
        sendGroupMessageError: null,
        formData: {}
    },
    reducers: {
        sendGroupMessageStart: (state) => {
            state.isLoadingSendGroupMessage = true;
            state.sendGroupMessageError = null;
        },
        sendGroupMessageSuccess: (state, actions) => {
            state.isLoadingSendGroupMessage = false;
            state.sendGroupMessageError = actions.payload;
        },
        sendGroupMessageFiled: (state, actions) => {
            state.isLoadingSendGroupMessage = false;
            state.sendGroupMessageError = actions.payload;
        },
        setFormData: (state, actions) => {
            state.formData[actions.payload[0]] = actions.payload[1]
        },

    },
});

export const { sendGroupMessageStart, sendGroupMessageSuccess, sendGroupMessageFiled, setFormData } = sendGroupMessageFormSlice.actions;
export default sendGroupMessageFormSlice.reducer;
