import { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import environment from '../environment';
import { useDispatch, useSelector } from 'react-redux';
import { open, receiveData } from '../slices/websocketSlice';
import withGuard from '../helpers/withGuard';
import SocketMessagesTypes from '../enums/SocketMessagesTypes';
import { toasts } from './toasts';
import { helperFunctions } from '../helpers/helperFunctions';
import { useRef } from 'react';


const WebSocketService = () => {
    const { profile } = useSelector(state => state.profile);

    const socketUrl = profile ? `${environment.SOCKET_URL}${profile.id}` : null;

    const {
        sendMessage,
        lastMessage,
        readyState
    } = useWebSocket(socketUrl);

    return {
        sendMessage,
        lastMessage,
        readyState
    };
};
function WebSocket() {
    const dispatch = useDispatch();
    const audioRef = useRef();
    const { sendMessage, lastMessage, readyState } = WebSocketService();

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            dispatch(open())
            if (lastMessage !== null) {
                try {
                    let jsonMessage = JSON.parse(lastMessage?.data)
                    dispatch(receiveData(jsonMessage))
                    // console.log("message:", jsonMessage);
                    if (jsonMessage.type === SocketMessagesTypes.newMessage) {
                        toasts.warning("رسالة جديدة من " + helperFunctions.getNumber(jsonMessage.message.from))
                        audioRef.current.play();
                    }
                } catch { }
            }
        }
    }, [lastMessage, dispatch, connectionStatus, sendMessage, readyState]);


    console.log("The WebSocket is currently ", connectionStatus)
    return (
        <audio ref={audioRef}>
            <source src="../assets/tons/msg.mp3" type="audio/mp3" />
        </audio>
    )
};

export default (withGuard(WebSocket))