import React, { useEffect, useState } from 'react'
import withGuard from '../../helpers/withGuard'
import { useSelector } from 'react-redux';
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout';
import MainButton from '../../components/buttons/mainButton/MainButton';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import QRCode from 'qrcode.react';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import { toasts } from '../../components/toasts';
import { whatsappService } from '../../services/whatsapp.service';
import { handelErrorsService } from '../../services/handelErrors.service';
import SocketMessagesTypes from '../../enums/SocketMessagesTypes';
import { Alert } from 'react-bootstrap';

function CreateWhatsappConnection() {
    const [isLoadingInitWhatsapp, setIsLoadingInitWhatsapp] = useState(false)
    const [isUserExists, setIsUserExists] = useState(false)
    const [isWhatsappReady, setIsWhatsappReady] = useState(false)
    const { connected, messages } = useSelector((state) => state.websocket);

    async function initWhatsapp() {
        if (connected) {
            setIsLoadingInitWhatsapp(true)
            const res = await whatsappService.init();
            if (res.status === 200) {
                if (res.data.success) {
                    toasts.success("جاري جلب البيانات من الواتساب..")
                    setIsLoadingInitWhatsapp(false)
                } else {
                    toasts.error(res.data.message)
                    setIsLoadingInitWhatsapp(false)
                }
            } else {
                const error = handelErrorsService.handel(res.status, res.data.message)
                toasts.error(error)
                setIsLoadingInitWhatsapp(false)
            }
        } else {
            toasts.error("حدث خطأ في الاتصال بتطبيق الواتساب يرجى تحديث الصفحة واعادة المحاولة")
            setIsLoadingInitWhatsapp(false)
        }
    }

    useEffect(() => {
        if (messages.length > 0) {
            if (messages[messages.length - 1].type === SocketMessagesTypes.ready) {
                setIsWhatsappReady(true)
            } else if (messages[messages.length - 1].type === SocketMessagesTypes.userExists) {
                setIsUserExists(true)
            }
        }
    }, [messages])


    return (
        <DefaultLayout title={'الاتصال بالواتساب'}>
            <div className='row mb-5'>
                <MainButton onClick={async () => await initWhatsapp()}
                    disabled={messages.length > 0 ? messages[messages.length - 1].type === SocketMessagesTypes.userExists : false}
                    text={isLoadingInitWhatsapp ? <BtnLoading /> : "طلب رمز ال QR"} />
            </div>
            {messages.length > 0 ?
                <div className='row py-4 my-2 justify-content-center'>
                    {messages[messages.length - 1].type === SocketMessagesTypes.qr ?
                        <QRCode size={400} value={messages[messages.length - 1].message} />
                        : isWhatsappReady ?
                            <div className='d-flex justify-content-center'>
                                <Alert variant='success'>الواتساب متصل!</Alert>
                            </div> :
                            isUserExists ?
                                <div className='d-flex justify-content-center'>
                                    <Alert variant='info'>المستخدم موجود</Alert>
                                </div>
                                : <div className='d-flex justify-content-center'>
                                    <SpinnerLoading />
                                </div>
                    }
                </div> : ""}
        </DefaultLayout>
    )
}

export default withGuard(CreateWhatsappConnection)
