import { createSlice } from '@reduxjs/toolkit';

const groupMessagesSlice = createSlice({
    name: 'groupMessages',
    initialState: {
        isLoadingGroupMessages: false,
        groupMessages: [],
        groupMessagesError: null
    },
    reducers: {
        gettingGroupMessagesStart: (state) => {
            state.isLoadingGroupMessages = true;
            state.groupMessages = [];
            state.groupMessagesError = null;
        },
        gettingGroupMessagesSuccess: (state, actions) => {
            state.isLoadingGroupMessages = false;
            state.groupMessages = actions.payload;
            state.groupMessagesError = null;
        },
        gettingGroupMessagesFiled: (state, actions) => {
            state.isLoadingGroupMessages = false;
            state.groupMessages = [];
            state.groupMessagesError = actions.payload;
        }
    },
});

export const { gettingGroupMessagesStart, gettingGroupMessagesSuccess, gettingGroupMessagesFiled } = groupMessagesSlice.actions;
export default groupMessagesSlice.reducer;
