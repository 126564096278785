import { createSlice } from '@reduxjs/toolkit';

const deleteCategorySlice = createSlice({
    name: 'deleteCategory',
    initialState: {
        isLoadingDeleteCategory: false,
        deleteCategoryError: null,
        deleteCategoryId: null
    },
    reducers: {
        deleteCategoryStart: (state, actions) => {
            state.isLoadingDeleteCategory = true;
            state.deleteCategoryError = null;
            state.deleteCategoryId = actions.payload
        },
        deleteCategorySuccess: (state) => {
            state.isLoadingDeleteCategory = false;
            state.deleteCategoryId = null
        },
        deleteCategoryFiled: (state, actions) => {
            state.isLoadingDeleteCategory = false;
            state.deleteCategoryError = actions.payload;
            state.deleteCategoryId = null
        },
    },
});

export const { deleteCategoryStart, deleteCategorySuccess, deleteCategoryFiled } = deleteCategorySlice.actions;
export default deleteCategorySlice.reducer;
