import { createSlice } from '@reduxjs/toolkit';

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        isLoadingCategories: false,
        categories: [],
        categoriesError: null
    },
    reducers: {
        gettingCategoriesStart: (state) => {
            state.isLoadingCategories = true;
            state.categories = [];
            state.categoriesError = null;
        },
        gettingCategoriesSuccess: (state, actions) => {
            state.isLoadingCategories = false;
            state.categories = actions.payload;
            state.categoriesError = null;
        },
        gettingCategoriesFiled: (state, actions) => {
            state.isLoadingCategories = false;
            state.categories = [];
            state.categoriesError = actions.payload;
        }
    },
});

export const { gettingCategoriesStart, gettingCategoriesSuccess, gettingCategoriesFiled } = categoriesSlice.actions;
export default categoriesSlice.reducer;
