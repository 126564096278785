import { createSlice } from '@reduxjs/toolkit';

const loginFormSlice = createSlice({
    name: 'loginForm',
    initialState: {
        isLoading: false,
        loginError: null,
    },
    reducers: {
        loginStart: (state) => {
            state.isLoading = true;
            state.loginError = null;
        },
        loginSuccess: (state) => {
            state.isLoading = false;
        },
        loginFiled: (state, actions) => {
            state.isLoading = false;
            state.loginError = actions.payload;
        },
    },
});

export const { loginStart, loginSuccess, loginFiled } = loginFormSlice.actions;
export default loginFormSlice.reducer;
