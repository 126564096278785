import React, { useState } from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { Card, FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import withGuard from '../../helpers/withGuard';
import { useDispatch, useSelector } from 'react-redux';
import * as formik from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { clientsCategoriesService } from '../../services/clientsCategories.service';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { toasts } from '../../components/toasts';
import { setCategory } from '../../slices/formsSlices/addEditCategoryFormSlice';

const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

const validationSchema = Yup.object().shape({
    name: Yup.string().required("اسم الفئة مطلوب"),
    color: Yup.string().required("اللون الفئة مطلوب"),
    // image: Yup.mixed()
    //     .test('fileType', 'صيغة الصورة غير مقبولة', (value) => {
    //         if (value) {
    //             return supportedFormats.includes(value.type);
    //         }
    //         return true;
    //     })
    //     .test('fileSize', 'الملف كبير جدا', (value) => {
    //         if (value) {
    //             return value.size <= maxSizeInBytes;
    //         }
    //         return true;
    //     }),
});

function AddEditCategoryPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const { isLoadingAddEditCategory, addEditCategoryError, category } = useSelector((state) => state.addEditCategoryForm);
    const [image, setImage] = useState(null)

    const handleSubmit = async (values, { setSubmitting }) => {
        let res

        const data = {
            name: values.name,
            color: values.color,
            image: image,
        }

        if (category == null) {
            res = await clientsCategoriesService.add(dispatch, data);
        } else {
            res = await clientsCategoriesService.edit(dispatch, data, category.id);
        }
        if (res) {
            navigate('/categories');
            if (category == null) {
                toasts.success("تمت إضافة صنف جديد");
            } else {
                toasts.success("تم تحديث البيانات بنجاح")
            }
        } else {
            toasts.error(addEditCategoryError);
        }
        setSubmitting(false);
        dispatch(setCategory(null))
    };

    return (
        <DefaultLayout title={category != null ? `تعديل بيانات فئة ${category.name}` : 'إضافة فئة جديدة'}>
            <Card className='p-4'>
                <Formik initialValues={{ name: category?.name || '', color: category?.color || '', image: null }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ handleChange, values, touched, errors, isSubmitting }) => (
                        <formik.Form encType='multipart/form-data'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <FloatingLabel controlId="name" label="اسم الفئة" className={'mb-3'}>
                                        <BootstrapForm.Control type="text" name="name" placeholder="مثال:عملاء مميزين" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && errors.name} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.name}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                                <div className='col-lg-6'>
                                    <FloatingLabel controlId="color" label="لون الفئة" className={'mb-3'}>
                                        <BootstrapForm.Control className='w-100' type="color" name="color" placeholder="مثال:#000" value={values.color}
                                            onChange={handleChange} isValid={touched.color && !errors.color} isInvalid={touched.color && errors.color} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.color}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                                <div className='col-lg-6'>
                                    <FloatingLabel controlId="image" label="صورة الفئة" className={'mb-3'}>
                                        <BootstrapForm.Control type="file" name="image" value={values.image} accept="image/*"
                                            onChange={(e) => {
                                                setImage(e.currentTarget.files[0])
                                            }}
                                            isValid={touched.image && !errors.image} isInvalid={touched.image && errors.image}
                                        />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.image}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-end'>
                                <MainButton type="submit" disabled={isSubmitting} text={isLoadingAddEditCategory ? <BtnLoading /> : category != null ? "تحديث" : "إضافة"} />
                            </div>
                        </formik.Form>
                    )}
                </Formik>
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(AddEditCategoryPage)