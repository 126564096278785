/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { CDBTableBody, CDBTableHeader, CDBTable } from 'cdbreact';
import withGuard from '../../helpers/withGuard';
import { ButtonGroup, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { clientsCategoriesService } from '../../services/clientsCategories.service';
import environment from '../../environment';
import MainButton from '../../components/buttons/mainButton/MainButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import DeleteIconButton from '../../components/buttons/iconButtons/DeleteIconButton';
import EditIconButton from '../../components/buttons/iconButtons/EditIconButton';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import { toasts } from '../../components/toasts';
import MainBadge from '../../components/badges/mainBadge/MainBadge';
import { setCategory } from '../../slices/formsSlices/addEditCategoryFormSlice';

function CategoriesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoadingCategories, categories, categoriesError } = useSelector((state) => state.categories);
    const { isLoadingDeleteCategory, deleteCategoryError, deleteCategoryId } = useSelector((state) => state.deleteCategory);


    useEffect(() => {
        dispatch(setCategory(null))
        clientsCategoriesService.getAll(dispatch);
    }, []);

    const editBtn = (category) => {
        dispatch(setCategory(category));
        navigate('edit');
    }

    const deleteBtn = async (category) => {
        const res = await clientsCategoriesService.remove(dispatch, category.id)
        if (res) {
            toasts.success("تمت حذف الصنف بنجاح");
            await clientsCategoriesService.getAll(dispatch)
        } else {
            toasts.error(deleteCategoryError);
        }
    }

    return (
        <DefaultLayout title={'أصناف العملاء'}>
            <Card className='p-4'>
                <div className='my-3 d-flex justify-content-between'>
                    <MainBadge text={`${categories.length} تصنيف للعملاء`} />
                    <Link to={'add'}>
                        <MainButton text={'اضافة جديد'} />
                    </Link>
                </div>
                {isLoadingCategories ?
                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <SpinnerLoading />
                    </div> : categoriesError ?
                        <div className='d-flex justify-content-center align-items-center py-5'>
                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await clientsCategoriesService.getAll(dispatch)} />
                        </div>
                        : <CDBTable hover responsive striped bordered>
                            <CDBTableHeader>
                                <tr className={'text-center'}>
                                    <th>#</th>
                                    <th>الاسم</th>
                                    <th>اللون</th>
                                    <th>الأيقونة</th>
                                    <th>الإجراءات</th>
                                </tr>
                            </CDBTableHeader>
                            <CDBTableBody>
                                {categories.length > 0 ?
                                    Array.from(categories).map((value, index) => (
                                        <tr key={index} className={'text-center'}>
                                            <td>{++index}</td>
                                            <td>{value.name}</td>
                                            <td style={{ backgroundColor: `${value.color}` }}></td>
                                            <td>{
                                                value?.image ?
                                                    <img className='w-25' src={`${environment.API_LINK_STORAGE}${value.image}`} alt='category' /> : "لا يوجد"
                                            }</td>
                                            <td>
                                                <ButtonGroup >
                                                    <DeleteIconButton onClick={() => deleteBtn(value)} disable={isLoadingDeleteCategory && value.id === deleteCategoryId} isLoading={isLoadingDeleteCategory && value.id === deleteCategoryId} />
                                                    <EditIconButton onClick={() => editBtn(value)} />
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className='text-center text-danger' colSpan={5}>لا يوجد بيانات</td>
                                    </tr>
                                }
                            </CDBTableBody>
                        </CDBTable>
                }
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(CategoriesPage);