import React from 'react';
import AuthLayout from '../layouts/authLayout/AuthLayout';
import notAllowed from '../../helpers/notAllowed';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { authService } from '../../services/auth.service';
import { Button, FloatingLabel, Form as BootstrapForm } from 'react-bootstrap';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import * as formik from 'formik';
import { toasts } from '../../components/toasts';

const validationSchema = Yup.object().shape({
    phone: Yup.string().min(8).required("يجب ادخال رقم"),
    password: Yup.string().min(8).required("يجب ادخال رقم"),
});
function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const { isLoading, loginError } = useSelector((state) => state.loginForm);

    const handleSubmit = async (values, { setSubmitting }) => {
        const res = await authService.login(dispatch, values)
        if (res) {
            await authService.getProfile()
            toasts.success("تم تسجيل الدخول بنجاح")
            navigate('/')
        } else {
            toasts.error(loginError);
        }
        setSubmitting(false)
    };

    return (
        <AuthLayout title={'تسجيل الدخول'} >
            <Formik initialValues={{ phone: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                {({ handleChange, values, touched, errors, isSubmitting }) => (
                    <Form className='d-flex flex-column justify-content-center align-items-center'>
                        <FloatingLabel controlId="phone" label="رقم الموبايل" className={'mb-3'}>
                            <BootstrapForm.Control type="tel" name="phone" placeholder="963993004230" value={values.phone}
                                onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && errors.phone} />
                            <BootstrapForm.Control.Feedback type="invalid">
                                {errors.phone}
                            </BootstrapForm.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel controlId="password" label="كلمة المرور" className={'mb-3 '}>
                            <BootstrapForm.Control type="password" name="password" placeholder="min 8." value={values.password}
                                onChange={handleChange} isValid={touched.password && !errors.password} isInvalid={touched.password && errors.password} />
                            <BootstrapForm.Control.Feedback type="invalid">
                                {errors.password}
                            </BootstrapForm.Control.Feedback>
                        </FloatingLabel>
                        {isLoading ? (
                            <BtnLoading />
                        ) : (
                            <Button type="submit" disabled={isSubmitting} className="btn">تسجيل دخول</Button>
                        )}

                    </Form>
                )}
            </Formik>
            <div className="text-center mt-4">
                <p> ليس لديك حساب ؟
                    <Link to={'/register'} replace={true} className='mx-1'>
                        إنشاء حساب
                    </Link>
                </p>
            </div>
        </AuthLayout>
    );
}

export default notAllowed(LoginPage);