import { configureStore } from "@reduxjs/toolkit";
import loginFormSlice from "./slices/formsSlices/loginFormSlice";
import authSlice from "./slices/authSlice";
import profileSlice from "./slices/profileSlice";
import clientsSlice from "./slices/clientsSlice";
import categoriesSlice from "./slices/categoriesSlice";
import sendGroupMessageFormSlice from "./slices/formsSlices/sendGroupMessageFormSlice";
import websocketSlice from "./slices/websocketSlice";
import repliesSlice from "./slices/repliesSlice";
import templatesSlice from "./slices/templatesSlice";
import deleteClientSlice from "./slices/deleteSlices/deleteClientSlice";
import countriesSlice from "./slices/countriesSlice";
import addEditCountryFormSlice from "./slices/formsSlices/addEditCountryFormSlice";
import groupMessagesSlice from "./slices/groupMessagesSlice";
import logoutSlice from "./slices/deleteSlices/logoutSlice";
import addEditCategoryFormSlice from "./slices/formsSlices/addEditCategoryFormSlice";
import deleteCategorySlice from "./slices/deleteSlices/deleteCategorySlice";
import addEditClientFormSlice from "./slices/formsSlices/addEditClientFormSlice";
import deleteCountrySlice from "./slices/deleteSlices/deleteCountrySlice";
import addEditReplyFormSlice from "./slices/formsSlices/addEditReplyFormSlice";
import deleteReplySlice from "./slices/deleteSlices/deleteReplySlice";
import citesSlice from "./slices/citesSlice";
import registerFormSlice from "./slices/formsSlices/registerFormSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    websocket: websocketSlice,
    profile: profileSlice,
    clients: clientsSlice,
    categories: categoriesSlice,
    templates: templatesSlice,
    replies: repliesSlice,
    countries: countriesSlice,
    groupMessages: groupMessagesSlice,
    loginForm: loginFormSlice,
    addEditClientForm: addEditClientFormSlice,
    addEditCategoryForm: addEditCategoryFormSlice,
    sendGroupMessageForm: sendGroupMessageFormSlice,
    addEditReplyForm: addEditReplyFormSlice,
    addEditCountryForm: addEditCountryFormSlice,
    deleteClient: deleteClientSlice,
    deleteCategory: deleteCategorySlice,
    deleteCountry: deleteCountrySlice,
    deleteReply: deleteReplySlice,
    logout: logoutSlice,
    cites: citesSlice,
    registerForm: registerFormSlice
  }
});

export default store;
