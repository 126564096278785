import React from 'react'
import { Badge } from 'react-bootstrap'
import { Styles } from './styles'

export default function MainBadge({ text }) {
    return (
        <Styles>
            <Badge bg='danger'>
                {text}
            </Badge>
        </Styles>
    )
}
