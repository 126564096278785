import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        isLoadingProfile: false,
        profile: null,
        profileError: null,
        userId: localStorage.getItem("userId")
    },
    reducers: {
        gettingProfileStart: (state) => {
            state.isLoadingProfile = true;
            state.profile = null;
            state.profileError = null;
        },
        gettingProfileSuccess: (state, actions) => {
            state.isLoadingProfile = false;
            state.profile = actions.payload;
            state.profileError = null;
        },
        gettingProfileFiled: (state, actions) => {
            state.isLoadingProfile = false;
            state.profile = null;
            state.profileError = actions.payload;
        },
        setUserId: (state, actions) => {
            localStorage.setItem("userId", actions.payload)
            state.userId = actions.payload;
        }
    },
});

export const { gettingProfileStart, gettingProfileSuccess, gettingProfileFiled } = profileSlice.actions;
export default profileSlice.reducer;
