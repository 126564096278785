/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import withGuard from '../../helpers/withGuard';
import { ButtonGroup, Card, Table } from 'react-bootstrap';
import { helperFunctions } from '../../helpers/helperFunctions';
import { Link, useNavigate } from 'react-router-dom';
import EditIconButton from '../../components/buttons/iconButtons/EditIconButton';
import DeleteIconButton from '../../components/buttons/iconButtons/DeleteIconButton';
import MainButton from '../../components/buttons/mainButton/MainButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import { toasts } from '../../components/toasts';
import Paginator from '../../components/pagination/Paginator';
import MainBadge from '../../components/badges/mainBadge/MainBadge';
import { repliesService } from '../../services/replies.service';
import MessageTypes from '../../enums/MessageTypes';
import environment from '../../environment';
import { setReply } from '../../slices/formsSlices/addEditReplyFormSlice';

function RepliesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState();
    const { isLoadingReplies, replies, errorReplies } = useSelector((state) => state.replies);
    const { isLoadingDeleteReply, deleteReplyError, deleteReplyId } = useSelector((state) => state.deleteReply);

    useEffect(() => {
        dispatch(setReply(null))
        getReplies(currentPage);
    }, []);


    async function getReplies(pageNumber) {
        dispatch(setReply(null))
        const res = await repliesService.getAll(dispatch, pageNumber);
        if (res.success) {
            setCurrentPage(res.currentPage)
            setItemsCount(res.itemsCount)
        } else {
            toasts.error(errorReplies)
        }
    }

    const editBtn = (reply) => {
        dispatch(setReply(reply));
        navigate('edit');
    }

    const deleteBtn = async (reply) => {
        const res = await repliesService.remove(dispatch, reply.id)
        if (res) {
            toasts.success("تمت حذف الرد بنجاح");
            await getReplies(currentPage)
        } else {
            toasts.error(deleteReplyError);
        }
    }

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        await getReplies(pageNumber)
    };

    return (
        <DefaultLayout title={'الردود'}>
            <Card className='p-4'>
                <div className='my-3 d-flex justify-content-between'>
                    <MainBadge text={`${itemsCount ?? 0} ردود`} />
                    <Link to={'add'}>
                        <MainButton text='اضافة جديد' />
                    </Link>
                </div>
                {isLoadingReplies ?
                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <SpinnerLoading />
                    </div> : errorReplies ?
                        <div className='d-flex justify-content-center align-items-center py-5'>
                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await getReplies()} />
                        </div> :
                        <Table hover responsive striped bordered>
                            <thead>
                                <tr className={'text-center'}>
                                    <th>#</th>
                                    <th>الكلمات المفتاحية</th>
                                    <th>نوع القالب</th>
                                    <th>المحتوى</th>
                                    <th>تاريخ انشاء</th>
                                    <th>الإجراءات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {replies.length > 0 ?
                                    Array.from(replies).map((value, key) => (
                                        <tr key={key} className={'text-center'}>
                                            <td>{++key}</td>
                                            <td>
                                                <p className='text-wrap'>{value.words == null ? "لا يوجد" : value.words}</p>
                                            </td>
                                            <td>{helperFunctions.getMessageTypeName(value.type)}</td>
                                            <td className='d-flex flex-column text-center'>{value.type === MessageTypes.text ?
                                                value.message.body
                                                : value.type === MessageTypes.location ?
                                                    <>
                                                        <span className='mb-1 w-100'> العنوان: {value.message.description}</span>
                                                        <span className='mb-1 w-100'>خط الطول: {value.message.latitude}</span>
                                                        <span className='mb-1 w-100'>خط العرض: {value.message.longitude}</span>
                                                    </> : value.type === MessageTypes.media ? <img className='img-fluid w-25' src={value.message.mediaUrl} /> : ""}</td>
                                            <td>{value.createdAt || "لا يوجد"}</td>
                                            <td>
                                                <ButtonGroup >
                                                    <DeleteIconButton onClick={() => deleteBtn(value)} disable={isLoadingDeleteReply && value.id === deleteReplyId} isLoading={isLoadingDeleteReply && value.id === deleteReplyId} />
                                                    {/* <EditIconButton onClick={() => editBtn(value)} /> */}
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className='text-center text-danger' colSpan={9}>لا يوجد بيانات</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                }
                <Paginator
                    totalItems={itemsCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(RepliesPage);