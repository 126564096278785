import { createSlice } from '@reduxjs/toolkit';

const deleteClientSlice = createSlice({
    name: 'deleteClient',
    initialState: {
        isLoadingDeleteClient: false,
        deleteClientError: null,
        deleteClientId: null,
    },
    reducers: {
        deleteClientStart: (state, actions) => {
            state.isLoadingDeleteClient = true;
            state.deleteClientError = null;
            state.deleteClientId = actions.payload
        },
        deleteClientSuccess: (state) => {
            state.isLoadingDeleteClient = false;
            state.deleteClientId = null
        },
        deleteClientFiled: (state, actions) => {
            state.isLoadingDeleteClient = false;
            state.deleteClientError = actions.payload;
            state.deleteClientId = null
        },
    },
});

export const { deleteClientStart, deleteClientSuccess, deleteClientFiled } = deleteClientSlice.actions;
export default deleteClientSlice.reducer;
