import { createSlice } from '@reduxjs/toolkit';

const addEditCountryFormSlice = createSlice({
    name: 'addEditCountryForm',
    initialState: {
        isLoadingAddEditCountry: false,
        addEditCountryError: null,
        country: null,
    },
    reducers: {
        setCountry: (state, actions) => {
            state.isLoadingAddEditCountry = false;
            state.addEditCountryError = null;
            state.country = actions.payload
        },
        addEditCountryStart: (state) => {
            state.isLoadingAddEditCountry = true;
            state.addEditCountryError = null;
        },
        addEditCountrySuccess: (state) => {
            state.isLoadingAddEditCountry = false;
        },
        addEditCountryFiled: (state, actions) => {
            state.isLoadingAddEditCountry = false;
            state.addEditCountryError = actions.payload;
        },
    },
});

export const { setCountry, addEditCountryStart, addEditCountrySuccess, addEditCountryFiled } = addEditCountryFormSlice.actions;
export default addEditCountryFormSlice.reducer;
