/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { Card, FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import withGuard from '../../helpers/withGuard';
import { useDispatch, useSelector } from 'react-redux';
import * as formik from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { clientsService } from '../../services/clients.service';
import { clientsCategoriesService } from '../../services/clientsCategories.service';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { toasts } from '../../components/toasts';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import { countriesService } from '../../services/countries.service';
import { setClient } from '../../slices/formsSlices/addEditClientFormSlice';


const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, "الاسم على الأقل من 2 محارف"),
    address: Yup.string().min(10, "العنوان على الأقل من 10 محارف"),
    country: Yup.number("يجب ان تكون البلدة رقما"),
    category_id: Yup.number("يجب ان يكون الصنف رقما"),
    phone_number: Yup.number("يجب ان يكون الرقم مكون من أرقام").required('رقم الموبايل مطلوب'),
});

function AddEditClientPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const { isLoadingAddEditClient, addEditClientError, client } = useSelector((state) => state.addEditClientForm);
    const { isLoadingCategories, categories, categoriesError } = useSelector((state) => state.categories);
    const { isLoadingCountries, countries, countriesError } = useSelector((state) => state.countries);

    useEffect(() => {
        clientsCategoriesService.getAll(dispatch)
        countriesService.getAll(dispatch)
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        let res = null
        if (client == null) {
            res = await clientsService.add(dispatch, values);
        } else {
            res = await clientsService.edit(dispatch, values, client.id)
        }
        if (res) {
            navigate('/clients');
            if (client == null) {
                toasts.success("تم إضافة العميل بنجاح")
            } else {
                toasts.success("تم تحديث البيانات بنجاح")
            }
        } else {
            toasts.error(addEditClientError)
        }
        setSubmitting(false)
        dispatch(setClient(null))
    };

    return (
        <DefaultLayout title={'إضافة عميل جديد'}>
            <Card className='p-4'>
                {isLoadingCategories || isLoadingCountries ?
                    <div className='d-flex justify-content-center align-items-center'>
                        <SpinnerLoading />
                    </div> :
                    <Formik initialValues={{ name: client?.name || '', address: client?.address || '', country_id: client?.country?.id || '', category_id: client?.category?.id || '', phone_number: client?.phoneNumber || '', is_blocked: client?.isBlocked || false, }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}>
                        {({ handleChange, values, touched, errors, isSubmitting }) => (
                            <formik.Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <FloatingLabel controlId="name" label="اسم العميل" className={'mb-3'}>
                                            <BootstrapForm.Control type="text" name="name" placeholder="مثال:أمجد" value={values.name}
                                                onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && errors.name} />
                                            <BootstrapForm.Control.Feedback type="invalid">
                                                {errors.name}
                                            </BootstrapForm.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-lg-6'>
                                        <FloatingLabel controlId="address" label="عنوان العميل" className={'mb-3'}>
                                            <BootstrapForm.Control type="text" name="address" placeholder="مثال:حمص الناعورة" value={values.address}
                                                onChange={handleChange} isValid={touched.address && !errors.address} isInvalid={touched.address && errors.address} />
                                            <BootstrapForm.Control.Feedback type="invalid">
                                                {errors.address}
                                            </BootstrapForm.Control.Feedback>
                                        </FloatingLabel>
                                    </div>

                                    {countriesError ?
                                        <div className='d-flex justify-content-center align-items-center py-5'>
                                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await countriesService.getAll(dispatch)} />
                                        </div> :
                                        countries.length > 0 ?
                                            <div className='col-lg-6'>
                                                <FloatingLabel controlId="country_id" label="اختر بلد العميل" className='mb-3'>
                                                    <BootstrapForm.Select name="country_id" value={values.country_id}
                                                        onChange={handleChange} isValid={touched.country_id && !errors.country_id} isInvalid={touched.country_id && errors.country_id}>
                                                        <option>اختر بلد العميل</option>
                                                        {Array.from(countries).map((value, index) => (
                                                            client?.country?.id === value.id ?
                                                                <option value={value.id} selected key={index}>{value.name}</option>
                                                                :
                                                                <option value={value.id} key={index}>{value.name}</option>
                                                        ))}
                                                    </BootstrapForm.Select>
                                                    <BootstrapForm.Control.Feedback type="invalid">
                                                        {errors.country_id}
                                                    </BootstrapForm.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                            : ""}

                                    {categoriesError ?
                                        <div className='d-flex justify-content-center align-items-center py-5'>
                                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await clientsCategoriesService.getAll(dispatch)} />
                                        </div> :
                                        categories.length > 0 ?
                                            <div className='col-lg-6'>
                                                <FloatingLabel controlId="category_id" label="اختر تصنيف للعميل">
                                                    <BootstrapForm.Select name="category_id" value={values.category_id}
                                                        onChange={handleChange} isValid={touched.category_id && !errors.category_id} isInvalid={touched.category_id && errors.category_id}>
                                                        <option>اختر تصنيف للعميل</option>
                                                        {Array.from(categories).map((value, index) => (
                                                            client?.category?.id === value.id ?
                                                                <option value={value.id} selected key={index}>{value.name}</option>
                                                                :
                                                                <option value={value.id} key={index}>{value.name}</option>
                                                        ))}
                                                    </BootstrapForm.Select>
                                                    <BootstrapForm.Control.Feedback type="invalid">
                                                        {errors.category_id}
                                                    </BootstrapForm.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                            : ""}
                                    <div className='col-lg-6'>
                                        <FloatingLabel controlId="phone_number" label="رقم موبايل العميل" className={'mb-3'}>
                                            <BootstrapForm.Control type="text" name="phone_number" placeholder="مثال:963993004230" value={values.phone_number}
                                                onChange={handleChange} isValid={touched.phone_number && !errors.phone_number} isInvalid={touched.phone_number && errors.phone_number} />
                                            <BootstrapForm.Control.Feedback type="invalid">
                                                {errors.phone_number}
                                            </BootstrapForm.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </div>
                                <div className='col-lg-12 d-flex justify-content-end'>
                                    <MainButton type="submit" disabled={isSubmitting} text={isLoadingAddEditClient ? <BtnLoading /> : client == null ? "إضافة" : "تحديث"} />
                                </div>
                            </formik.Form>
                        )}
                    </Formik>
                }
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(AddEditClientPage)