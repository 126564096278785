/* eslint-disable eqeqeq */
import { loginFiled, loginStart, loginSuccess } from "../slices/formsSlices/loginFormSlice";
import { gettingProfileFiled, gettingProfileStart, gettingProfileSuccess } from "../slices/profileSlice";
import { axiosService } from "./axios.service";
import { removeLoginInfo, setLoginInfo } from "../slices/authSlice";
import { logoutFiled, logoutStart, logoutSuccess } from "../slices/deleteSlices/logoutSlice";
import { registerFiled, registerStart, registerSuccess } from "../slices/formsSlices/registerFormSlice";

async function login(dispatch, body) {
  try {
    dispatch(loginStart())
    const res = await axiosService.post('auth/login', body)

    if (res.status === 200) {
      const authInfo = {
        token: res.data.access_token,
        userType: res.data.type,
      }
      dispatch(loginSuccess());
      dispatch(setLoginInfo(authInfo));
      return true
    } else if (res.status == 401) {
      dispatch(loginFiled("رقم الموبايل وكلمة المرور غير متطابقان"));
      return false
    } else {
      dispatch(loginFiled(res.error.message));
      return false
    }
  } catch (error) {
    dispatch(loginFiled());
    return false
  }
};

async function register(dispatch, body) {
  try {
    dispatch(registerStart())
    const res = await axiosService.post('auth/register', body)
    if (res.status === 200) {
      dispatch(registerSuccess());
      return true
    } else {
      dispatch(registerFiled(res.error.message));
      return false
    }
  } catch (error) {
    dispatch(registerFiled());
    return false
  }
};

async function getProfile(dispatch) {
  try {
    dispatch(gettingProfileStart());
    const res = await axiosService.get('auth/me')
    if (res.status === 200) {
      dispatch(gettingProfileSuccess(res.data.data));
    } else {
      dispatch(gettingProfileFiled(res.message));
    }
  } catch (error) {
    dispatch(gettingProfileFiled());
  }
}

async function logout(dispatch) {
  try {
    dispatch(logoutStart())
    const res = await axiosService.get('auth/logout');
    if (res.status === 200) {
      dispatch(logoutSuccess())
      dispatch(removeLoginInfo())
    } else {
      dispatch(logoutFiled())
    }
  } catch (error) {
    dispatch(logoutFiled())
  }
};

export const authService = {
  login, getProfile, logout, register
}