import { gettingCitesFiled, gettingCitesStart, gettingCitesSuccess } from "../slices/citesSlice";
import { gettingCountriesFiled, gettingCountriesStart, gettingCountriesSuccess } from "../slices/countriesSlice";
import { deleteCountryFiled, deleteCountryStart, deleteCountrySuccess } from "../slices/deleteSlices/deleteCountrySlice";
import { axiosService } from "./axios.service";


async function getAllCitesFromAdmin(dispatch) {
  try {
    dispatch(gettingCitesStart());
    const res = await axiosService.get('cites/getAll');
    if (res.status === 200) {
      const dataRes = []
      res.data.data.forEach(element => {
        const city = {
          id: element.id,
          name: element.name,
        }
        dataRes.push(city);
      });
      dispatch(gettingCitesSuccess(dataRes))
    } else {
      dispatch(gettingCitesFiled(res.error.message))
    }
  } catch {
    dispatch(gettingCitesFiled())
  }
};

async function getAll(dispatch) {
  try {
    dispatch(gettingCountriesStart());
    const res = await axiosService.get('countries/getAll');
    if (res.status === 200) {
      const dataRes = []
      res.data.data.forEach(element => {
        const country = {
          id: element.id,
          name: element.name,
        }
        dataRes.push(country);
      });
      dispatch(gettingCountriesSuccess(dataRes))
    } else {
      dispatch(gettingCountriesFiled(res.error.message))
    }
  } catch {
    dispatch(gettingCountriesFiled())
  }
};

const add = async (body) => {
  const res = await axiosService.post('countries/add', body, true);
  return res;
};

const edit = async (body, country) => {
  const res = await axiosService.post(`countries/edit?country=${country}`, body);
  return res;
};

const remove = async (dispatch, country) => {
  try {
    dispatch(deleteCountryStart(country))
    const res = await axiosService.get(`countries/delete?country=${country}`);
    if (res.status === 200) {
      dispatch(deleteCountrySuccess());
      return true
    } else {
      dispatch(deleteCountryFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(deleteCountryFiled());
    return false
  }
};

export const countriesService = {
  getAll, add, edit, remove, getAllCitesFromAdmin
}