import styled from "styled-components";
export const Styles = styled.div`
main{
}

.form-card{
    height:600px;
    overflow-y:auto
}

.logo{
    width:100px
}

.form-floating,.card-body,.card-body form{
    width:100%
}
`;
