/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FloatingLabel, Form as BootstrapForm } from 'react-bootstrap';
import * as formik from 'formik';
import { countriesService } from '../../services/countries.service';
import { authService } from '../../services/auth.service';
import { Link, useNavigate } from 'react-router-dom';
import { toasts } from '../../components/toasts';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import notAllowed from '../../helpers/notAllowed';
import AuthLayout from '../layouts/authLayout/AuthLayout';
import CountryCodeArray from '../../components/countryCodeArray/CountryCodeArray';


const validationSchema = Yup.object().shape({
    name: Yup.string().required('الاسم مطلوب'),
    phone: Yup.string().min(8).required("يجب ادخال رقم"),
    password: Yup.string().min(8).required("يجب ادخال رقم"),
    email: Yup.string().email().required('الايمبل مطلوب'),
    country_id: Yup.number("يجب ان تكون البلدة رقما"),
});

function RegisterPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const { isLoadingRegister, registerError, countryCode } = useSelector((state) => state.registerForm);
    const { isLoadingCites, citesError, cites } = useSelector((state) => state.cites);

    async function getCites() {
        await countriesService.getAllCitesFromAdmin(dispatch)
    }
    useEffect(() => {
        getCites()
    }, []);

    const handleSubmit = async (values, { setSubmitting }) => {
        const data = {
            name: values.name,
            phone: countryCode.value.toString() + values.phone,
            country_id: values.country_id,
            email: values.email,
            password: values.password
        }
        const res = await authService.register(dispatch, data)
        if (res) {
            toasts.success("تم انشاء الحساب بنجاح")
            navigate('/login')
        } else {
            toasts.error(registerError);
        }
        setSubmitting(false)
    };
    return (
        <AuthLayout title={'انشاء حساب جديد'} >
            {isLoadingCites ?
                <div className='d-flex justify-content-center align-items-center'>
                    <SpinnerLoading />
                </div> :
                <Formik initialValues={{ name: '', phone: '', email: '', country_id: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, values, touched, errors, isSubmitting }) => (
                        <Form className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='row'>
                                <div className='col-12'>
                                    <FloatingLabel controlId="name" label=" الاسم الكامل" className={'mb-3'}>
                                        <BootstrapForm.Control type="text" name="name" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && errors.name} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.name}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-8' style={{ paddingInlineEnd: 0 }}>
                                            <FloatingLabel controlId="phone" label='رقم الهاتف' className={'mb-3'}>
                                                <BootstrapForm.Control type="text" name="phone" value={values.phone}
                                                    onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={touched.phone && errors.phone}
                                                />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.phone}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-4' style={{ paddingInlineStart: 0 }}>
                                            <CountryCodeArray />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <FloatingLabel controlId="email" label="البريد الالكتروني" className={'mb-3'}>
                                        <BootstrapForm.Control type="email" name="email" placeholder="ادخل البريد الالكتروني" value={values.email}
                                            onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={touched.email && errors.email} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.email}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                                <div className='col-12'>
                                    <FloatingLabel controlId="password" label="كلمة المرور" className={'mb-3'}>
                                        <BootstrapForm.Control type="password" name="password" placeholder="ادخل كلمة المرور" value={values.password}
                                            onChange={handleChange} isValid={touched.password && !errors.password} isInvalid={touched.password && errors.password} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.password}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                                <div className='col-12'>
                                    {citesError ?
                                        <div className='d-flex justify-content-center align-items-center py-5'>
                                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await countriesService.getAllCitesFromAdmin(dispatch)} />
                                        </div> :
                                        cites.length > 0 ?
                                            <FloatingLabel controlId="country_id" label="اختر بلد" className='mb-3'>
                                                <BootstrapForm.Select name="country_id" value={values.country_id}
                                                    onChange={handleChange} isValid={touched.country_id && !errors.country_id} isInvalid={touched.country_id && errors.country_id}>
                                                    <option label='اختر البلد' key={0}>اختر البلد</option>
                                                    {Array.from(cites).map((value, index) => (
                                                        <option value={value.id} key={index + 1}>{value.name}</option>
                                                    ))}
                                                </BootstrapForm.Select>
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.country_id}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                            : ""}
                                </div>
                                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                                    {isLoadingRegister ? (
                                        <BtnLoading />
                                    ) : (
                                        <Button type="submit" disabled={isSubmitting} className="btn w-100">إنشاء حساب</Button>
                                    )}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
            <div className="text-center mt-2">
                <p>  لديك حساب ؟
                    <Link to={'/login'} replace={true} className='mx-1'>
                        تسجيل الدخول
                    </Link>
                </p>
            </div>
        </AuthLayout>
    );
}

export default notAllowed(RegisterPage);