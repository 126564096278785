import MessageTypes from "../enums/MessageTypes";

function formatDate(date) {
    const inputDate = new Date(date); // Step 1
    const year = inputDate.getFullYear(); // Extract year (e.g., 2023)
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Extract month (e.g., 09)
    const day = String(inputDate.getDate()).padStart(2, '0'); // Extract day (e.g., 19)

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

function getNumber(fullNumber) {
    const res = fullNumber.split('@')
    return res[0]
}

function getMessageTypeName(type) {
    switch (type) {
        case MessageTypes.text:
            return "نصي"
        case MessageTypes.media:
            return "وسائط"
        case MessageTypes.location:
            return "موقع"
        case MessageTypes.button:
            return "أزرار تفاعلية"
        default:
            return "أخرى"
    }
}

export const helperFunctions = {
    formatDate, getNumber, getMessageTypeName
}