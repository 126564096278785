import { createSlice } from '@reduxjs/toolkit';

const logoutSlice = createSlice({
    name: 'logout',
    initialState: {
        isLoadingLogout: false,
        logoutError: null,
    },
    reducers: {
        logoutStart: (state) => {
            state.isLoadingLogout = true;
            state.logoutError = null;
        },
        logoutSuccess: (state) => {
            state.isLoadingLogout = false;
        },
        logoutFiled: (state, actions) => {
            state.isLoadingLogout = false;
            state.logoutError = actions.payload;
        },
    },
});

export const { logoutStart, logoutSuccess, logoutFiled } = logoutSlice.actions;
export default logoutSlice.reducer;
