import { helperFunctions } from "../helpers/helperFunctions";
import { gettingClientsFiled, gettingClientsStart, gettingClientsSuccess } from "../slices/clientsSlice";
import { deleteClientFiled, deleteClientStart, deleteClientSuccess } from "../slices/deleteSlices/deleteClientSlice";
import { addEditClientFiled, addEditClientStart, addEditClientSuccess } from "../slices/formsSlices/addEditClientFormSlice";
import { axiosService } from "./axios.service";


async function getAll(dispatch, pageNumber) {
  try {
    dispatch(gettingClientsStart());
    const res = await axiosService.get(`clients/getAll?page=${pageNumber}`);
    if (res.status === 200) {
      const dataRes = res.data.data.data.map(element => ({
        id: element.id,
        name: element?.name,
        address: element?.address,
        country: element?.country_id != null ? element?.country : null,
        category: element?.category_id != null ? element?.category : null,
        phoneNumber: element.phone_number,
        isBlocked: element.is_blocked,
        createdAt: element?.created_at ? helperFunctions.formatDate(element?.created_at) : null
      }));

      const result = {
        success: true,
        currentPage: res.data.data.current_page,
        itemsCount: res.data.data.total,
      };

      dispatch(gettingClientsSuccess(dataRes));
      return result;
    } else {
      const result = {
        success: false,
        currentPage: null,
        itemsCount: null,
      };

      dispatch(gettingClientsFiled(res.error.message));
      return result;
    }
  } catch (error) {
    const result = {
      success: false,
      currentPage: null,
      itemsCount: null,
    };

    dispatch(gettingClientsFiled(error?.message || "An error occurred"));
    return result;
  }
};


async function add(dispatch, body) {
  try {
    dispatch(addEditClientStart())
    const res = await axiosService.post('clients/add', body);
    if (res.status === 200) {
      dispatch(addEditClientSuccess());
      return true
    } else {
      dispatch(addEditClientFiled(res.error.message))
      return false
    }
  } catch (error) {
    dispatch(addEditClientFiled());
    return false
  }
}

const edit = async (dispatch, body, clientId) => {
  try {
    dispatch(addEditClientStart())
    const res = await axiosService.post(`clients/edit?client=${clientId}`, body);
    if (res.status === 200) {
      dispatch(addEditClientSuccess());
      return true
    } else {
      dispatch(addEditClientFiled(res.error.message))
      return false
    }
  } catch (error) {
    dispatch(addEditClientFiled());
    return false
  }
};

async function remove(dispatch, clientId) {
  try {
    dispatch(deleteClientStart(clientId));
    const res = await axiosService.get(`clients/delete?client=${clientId}`);
    if (res.status === 200) {
      dispatch(deleteClientSuccess())
      return true
    } else {
      dispatch(deleteClientFiled(res.error.message))
      return false
    }
  } catch (error) {
    dispatch(deleteClientFiled())
    return false
  }
};

async function asyncWithContacts(dispatch) {
  try {
    dispatch(gettingClientsStart());
    const res = await axiosService.get(`clients/asyncWithContacts`);
    if (res.status === 200) {
      dispatch(gettingClientsSuccess())
      return true
    } else {
      dispatch(gettingClientsFiled(res.error.message))
      return false
    }
  } catch {
    dispatch(gettingClientsFiled())
    return false
  }
};


export const clientsService = {
  getAll, add, edit, remove, asyncWithContacts
}