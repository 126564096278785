/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { clientsService } from '../../services/clients.service';
import withGuard from '../../helpers/withGuard';
import { ButtonGroup, Card, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import EditIconButton from '../../components/buttons/iconButtons/EditIconButton';
import DeleteIconButton from '../../components/buttons/iconButtons/DeleteIconButton';
import MainButton from '../../components/buttons/mainButton/MainButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import { toasts } from '../../components/toasts';
import Paginator from '../../components/pagination/Paginator';
import MainBadge from '../../components/badges/mainBadge/MainBadge';
import { setClient } from '../../slices/formsSlices/addEditClientFormSlice';
import TooltipComponent from '../../components/TooltipComponent';

function ClientsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState(0);
    const { isLoadingClients, clients, clientsError } = useSelector((state) => state.clients);
    const { isLoadingDeleteClient, deleteClientError, deleteClientId } = useSelector((state) => state.deleteClient);

    useEffect(() => {
        getClients();
    }, []);


    async function getClients(pageNumber) {
        dispatch(setClient(null))
        const res = await clientsService.getAll(dispatch, pageNumber);
        if (res.success) {
            setCurrentPage(res.currentPage)
            setItemsCount(res.itemsCount)
        } else {
            toasts.error(clientsError)
        }
    }

    const editBtn = (client) => {
        dispatch(setClient(client));
        navigate('edit');
    }

    const deleteBtn = async (client) => {
        const res = await clientsService.remove(dispatch, client.id);
        if (res) {
            toasts.success("تم حذف العميل بنجاح")
            await getClients(currentPage)
        } else {
            toasts.error(deleteClientError)
        }
    }

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        await getClients(pageNumber)
    };

    const syncChats = async () => {
        const res = await clientsService.asyncWithContacts(dispatch);
        if (res) {
            await getClients(1)
            toasts.success("تمت عملية المزامنة بنجاح")
        } else {
            toasts.error(clientsError)
        }
    }
    return (
        <DefaultLayout title={'العملاء'}>
            <Card className='p-4'>
                <div className='my-3 d-flex justify-content-between'>
                    <MainBadge text={`${itemsCount} عميل`} />
                    <div className='d-flex'>
                        <Link to={'add'} className='mx-2'>
                            <MainButton text='اضافة جديد' />
                        </Link>

                        {/* <TooltipComponent text={"Update to pro version!"}> */}
                        <MainButton text='مزامنة الدردشات' onClick={async () => await syncChats()} />
                        {/* </TooltipComponent> */}
                    </div>
                </div>
                {isLoadingClients ?
                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <SpinnerLoading />
                    </div> : clientsError ?
                        <div className='d-flex justify-content-center align-items-center py-5'>
                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await getClients(currentPage)} />
                        </div> :
                        <Table hover responsive striped bordered>
                            <thead>
                                <tr className={'text-center'}>
                                    <th>#</th>
                                    <th>الاسم</th>
                                    <th>العنوان</th>
                                    <th>الدولة</th>
                                    <th>تصنيف</th>
                                    <th>الرقم</th>
                                    <th>الحالة</th>
                                    <th>تاريخ الانضمام</th>
                                    <th>الإجراءات</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.length > 0 ?
                                    Array.from(clients).map((value, key) => (
                                        <tr key={key} className={'text-center'}>
                                            <td>{++key}</td>
                                            <td>{value.name || "لا يوجد"}</td>
                                            <td>{value.address || "لا يوجد"}</td>
                                            <td>{value?.country?.name || "لا يوجد"}</td>
                                            <td>{value?.category?.name || "لا يوجد"}</td>
                                            <td>{value.phoneNumber}</td>
                                            <td>{value.isBlocked === 1 ? "محظور" : "متاح"}</td>
                                            <td>{value.createdAt || "لا يوجد"}</td>
                                            <td>
                                                <ButtonGroup >
                                                    <DeleteIconButton onClick={() => deleteBtn(value)} disable={isLoadingDeleteClient && value.id === deleteClientId} isLoading={isLoadingDeleteClient && value.id === deleteClientId} />
                                                    <EditIconButton onClick={() => editBtn(value)} />
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className='text-center text-danger' colSpan={9}>لا يوجد بيانات</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                }
                <Paginator
                    totalItems={itemsCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(ClientsPage);