/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { Card, FloatingLabel, Form as BootstrapForm } from 'react-bootstrap'
import withGuard from '../../helpers/withGuard';
import { useDispatch, useSelector } from 'react-redux';
import * as formik from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { toasts } from '../../components/toasts';
import MessageTypes from '../../enums/MessageTypes';
import { setReply } from '../../slices/formsSlices/addEditReplyFormSlice';
import { repliesService } from '../../services/replies.service';


const validationSchema = Yup.object().shape({
    type: Yup.number("يجب ان يكون النوع رقما"),
    words: Yup.string().required("حقل الكلمات المفتاحية مطلوب"),
});

function AddEditReplyPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const [image, setImage] = useState(null)
    const { isLoadingAddEditReply, reply, addEditReplyError } = useSelector((state) => state.addEditReplyForm);
    const [messageType, setMessageType] = useState(reply?.type || MessageTypes.text)

    const handleSubmit = async (values, { setSubmitting }) => {
        let res = null
        values.media = image
        if (reply == null) {
            res = await repliesService.add(dispatch, values);
        } else {
            res = await repliesService.edit(dispatch, values, reply.id);
        }
        if (res) {
            navigate('/replies');
            if (reply == null) {
                toasts.success("تم إضافة الرد بنجاح")
            } else {
                toasts.success("تم تحديث الرد بنجاح")
            }
        } else {
            toasts.error(addEditReplyError)
        }
        setSubmitting(false);
        dispatch(setReply(null))
    };

    const handleMessageTypeChange = (event) => {
        const val = event.target.value;
        setMessageType(val)
    };

    return (
        <DefaultLayout title={'إنشاء رد جديد'}>
            <Card className='p-4'>
                <Formik initialValues={{ words: reply?.words || '', type: messageType, body: reply?.message?.body || '', media: '', latitude: reply?.message?.latitude || '', longitude: reply?.message?.longitude || '', description: reply?.message?.description || '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ handleChange, values, touched, errors, isSubmitting }) => (
                        <formik.Form encType="multipart/form-data">
                            <div className='row mb-3'>
                                <h5>يرجى ادخال الكلمات المفتاحية</h5>
                                <div className='col-lg-12'>
                                    <FloatingLabel controlId="words" label="الكلمات المفتاحية" className={'mb-3'}>
                                        <BootstrapForm.Control type="text" name="words" placeholder="مرحبا,هلا,كيفك" value={values.words}
                                            onChange={handleChange} isValid={touched.words && !errors.words} isInvalid={touched.words && errors.words} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.words}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <h5>يرجى تحديد نوع القالب</h5>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3" >
                                        <BootstrapForm.Check
                                            inline
                                            label="قالب نصي"
                                            name="type"
                                            type={type}
                                            value={MessageTypes.text}
                                            defaultChecked={messageType === MessageTypes.text}
                                            disabled={reply != null && messageType !== MessageTypes.text}
                                            onChange={(event) => {
                                                handleChange(event)
                                                handleMessageTypeChange(event)
                                            }}
                                        />
                                        {/* <BootstrapForm.Check
                                            inline
                                            label="قالب وسائط"
                                            name="type"
                                            type={type}
                                            defaultChecked={messageType === MessageTypes.media}
                                            value={MessageTypes.media}
                                            disabled={reply != null && messageType !== MessageTypes.media}
                                            onChange={(event) => {
                                                handleChange(event)
                                                handleMessageTypeChange(event)
                                            }}
                                        /> */}
                                        <BootstrapForm.Check
                                            inline
                                            label="قالب موقع"
                                            name="type"
                                            defaultChecked={messageType === MessageTypes.location}
                                            type={type}
                                            value={MessageTypes.location}
                                            disabled={reply != null && messageType !== MessageTypes.location}
                                            onChange={(event) => {
                                                handleChange(event)
                                                handleMessageTypeChange(event)
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className='row mb-3'>
                                <h5>يرجى ملء بيانات القالب</h5>
                                {parseInt(messageType) === MessageTypes.text ?
                                    <div className='col-lg-6'>
                                        <FloatingLabel controlId="body" label="الرسالة" className={'mb-3'}>
                                            <BootstrapForm.Control type="text" name="body" placeholder="مرحبا بك في شركة أمان " value={values.body}
                                                onChange={handleChange} isValid={touched.body && !errors.body} isInvalid={touched.body && errors.body} />
                                            <BootstrapForm.Control.Feedback type="invalid">
                                                {errors.body}
                                            </BootstrapForm.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                    : ''}

                                {parseInt(messageType) === MessageTypes.media ?
                                    <>
                                        {/* <div className='col-lg-6'>
                                            <FloatingLabel controlId="body" label="الرسالة" className={'mb-3'}>
                                                <BootstrapForm.Control type="text" name="body" placeholder="مرحبا بك في شركة أمان " value={values.body}
                                                    onChange={handleChange} isValid={touched.body && !errors.body} isInvalid={touched.body && errors.body} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.body}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div> */}
                                        <div className='col-lg-6'>
                                            <FloatingLabel controlId="media" label="ملف الوسائط" className={'mb-3'}>
                                                <BootstrapForm.Control type="file" name="media"
                                                    onChange={(e) => {
                                                        setImage(e.currentTarget.files[0])
                                                    }}
                                                    isValid={touched.media && !errors.media} isInvalid={touched.media && errors.media} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.media}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                    </>
                                    : ''}
                                {parseInt(messageType) === MessageTypes.location ?
                                    <>
                                        <div className='col-lg-6'>
                                            <FloatingLabel controlId="description" label="العنوان" className={'mb-3'}>
                                                <BootstrapForm.Control type="text" name="description" placeholder="حمص الساعة جديدة" value={values.description}
                                                    onChange={handleChange} isValid={touched.description && !errors.description} isInvalid={touched.description && errors.description} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.description}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-6'>
                                            <FloatingLabel controlId="latitude" label="خط الطول" className={'mb-3'}>
                                                <BootstrapForm.Control type="numbers" name="latitude" placeholder="حمص الساعة جديدة" value={values.latitude}
                                                    onChange={handleChange} isValid={touched.latitude && !errors.latitude} isInvalid={touched.latitude && errors.latitude} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.latitude}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-lg-6'>
                                            <FloatingLabel controlId="longitude" label="خط العرض" className={'mb-3'}>
                                                <BootstrapForm.Control type="numbers" name="longitude" placeholder="حمص الساعة جديدة" value={values.longitude}
                                                    onChange={handleChange} isValid={touched.longitude && !errors.longitude} isInvalid={touched.longitude && errors.longitude} />
                                                <BootstrapForm.Control.Feedback type="invalid">
                                                    {errors.longitude}
                                                </BootstrapForm.Control.Feedback>
                                            </FloatingLabel>
                                        </div>
                                    </>
                                    : ''}
                            </div>

                            <div className='col-lg-12 d-flex justify-content-end'>
                                <MainButton type="submit" disabled={isSubmitting} text={isLoadingAddEditReply ? <BtnLoading /> : reply != null ? "تحديث" : "إضافة"} />
                            </div>
                        </formik.Form>
                    )}
                </Formik>
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(AddEditReplyPage)