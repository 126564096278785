/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Styles } from './styles';
import logo from '../../../assets/images/logo.webp'
const AuthLayout = ({ children, title }) => {
    return (
        <Styles>
            <main className='d-flex justify-content-center align-items-center vh-100'>
                <div className='container d-flex justify-content-center align-items-center vh-100'>
                    <div className='row justify-content-center align-items-center w-100'>
                        <div className={`col-lg-4 py-1 card shadow  position-relative form-card d-flex flex-column justify-content-center align-items-center`}>
                            <div className="card-title d-flex flex-column align-items-center  justify-content-center al">
                                <div className='logo'>
                                    <img src={logo} className='w-100' alt='logo' />
                                </div>
                                <h2 className='m-0 h3 mb-1'>{title}</h2>
                            </div>
                            <div className="card-body py-0 d-flex flex-column justify-content-center align-items-center">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Styles>
    );
};

export default AuthLayout;
