import { createSlice } from '@reduxjs/toolkit';

const citesSlice = createSlice({
    name: 'cites',
    initialState: {
        isLoadingCites: false,
        cites: [],
        citesError: null
    },
    reducers: {
        gettingCitesStart: (state) => {
            state.isLoadingCites = true;
            state.cites = [];
            state.citesError = null;
        },
        gettingCitesSuccess: (state, actions) => {
            state.isLoadingCites = false;
            state.cites = actions.payload;
            state.citesError = null;
        },
        gettingCitesFiled: (state, actions) => {
            state.isLoadingCites = false;
            state.cites = [];
            state.citesError = actions.payload;
        }
    },
});

export const { gettingCitesStart, gettingCitesSuccess, gettingCitesFiled } = citesSlice.actions;
export default citesSlice.reducer;
