import { createSlice } from '@reduxjs/toolkit';

const addEditClientFormSlice = createSlice({
    name: 'addEditClientForm',
    initialState: {
        isLoadingAddEditClient: false,
        addEditClientError: null,
        client: null
    },
    reducers: {
        setClient: (state, actions) => {
            state.isLoadingAddEditClient = false;
            state.addEditClientError = null;
            state.client = actions.payload
        },
        addEditClientStart: (state) => {
            state.isLoadingAddEditClient = true;
            state.addEditClientError = null;
        },
        addEditClientSuccess: (state) => {
            state.isLoadingAddEditClient = false;
        },
        addEditClientFiled: (state, actions) => {
            state.isLoadingAddEditClient = false;
            state.addEditClientError = actions.payload;
        },
    },
});

export const { addEditClientStart, addEditClientSuccess, addEditClientFiled, setClient } = addEditClientFormSlice.actions;
export default addEditClientFormSlice.reducer;
