import React from 'react'
import { Button } from 'react-bootstrap'
import { Styles } from './styles'
import BtnLoading from '../../loading/btnLoading/BtnLoading'

export default function DeleteIconButton({ onClick, disable, isLoading }) {
  return (
    <Styles>
      <Button className='mx-2 rounded' variant="danger" onClick={onClick} disable={disable}>
        {
          isLoading ?
            <BtnLoading />
            : <i className='fa fa-trash-can'></i>
        }
      </Button>
    </Styles>
  )
}
