import { createSlice } from '@reduxjs/toolkit';

const addEditReplyFormSlice = createSlice({
    name: 'addEditReplyForm',
    initialState: {
        isLoadingAddEditReply: false,
        addEditReplyError: null,
        reply: null,
    },
    reducers: {
        setReply: (state, actions) => {
            state.isLoadingAddEditReply = false;
            state.addEditReplyError = null;
            state.reply = actions.payload
        },
        addEditReplyStart: (state) => {
            state.isLoadingAddEditReply = true;
            state.addEditReplyError = null;
        },
        addEditReplySuccess: (state) => {
            state.isLoadingAddEditReply = false;
        },
        addEditReplyFiled: (state, actions) => {
            state.isLoadingAddEditReply = false;
            state.addEditReplyError = actions.payload;
        },
    },
});

export const { addEditReplyStart, addEditReplySuccess, addEditReplyFiled, setReply } = addEditReplyFormSlice.actions;
export default addEditReplyFormSlice.reducer;
