import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const withGuard = (Component) => {
    const Wrapper = (props) => {
        const { isLoggedIn } = useSelector((state) => state.auth);

        return isLoggedIn ? (
            <Component {...props} />
        ) : (
            <Navigate to={'/login'} replace />
        );
    };
    return Wrapper;
};

export default withGuard;