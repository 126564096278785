import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import ClientsPage from './pages/clients/ClientsPage';
import CategoriesPage from './pages/categories/CategoriesPage';
import SendGroupMessage from './pages/groupMessages/SendGroupMessage';
import CreateWhatsappConnection from './pages/whatsappConnection/CreateWhatsappConnection';
import WhatsApp from './pages/whatsApp/whatsApp';
import RepliesPage from './pages/replies/RepliesPage';
import CountriesPage from './pages/countries/CountriesPage';
import AddEditCountryPage from './pages/countries/AddEditCountryPage';
import GroupMessagesPage from './pages/groupMessages/GroupMessagesPage';
import AddEditCategoryPage from './pages/categories/AddEditCategoryPage';
import AddEditClientPage from './pages/clients/AddEditClientPage';
import AddEditReplyPage from './pages/replies/AddEditReplyPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';

function App() {

  return (

    <Routes>

      <Route index element={<HomePage />} />
      <Route path={'/login'} element={<LoginPage />} />
      <Route path={'/register'} element={<RegisterPage />} />

      <Route index element={<HomePage />} />

      <Route path={'/clients'} element={<ClientsPage />} />
      <Route path={'/clients/add'} element={<AddEditClientPage />} />
      <Route path={'/clients/edit'} element={<AddEditClientPage />} />

      <Route path={'/categories'} element={<CategoriesPage />} />
      <Route path={'/categories/add'} element={<AddEditCategoryPage />} />
      <Route path={'/categories/edit'} element={<AddEditCategoryPage />} />

      <Route path={'/countries'} element={<CountriesPage />} />
      <Route path={'/countries/add'} element={<AddEditCountryPage />} />
      <Route path={'/countries/edit'} element={<AddEditCountryPage />} />

      <Route path={'/group-messages'} element={<GroupMessagesPage />} />
      <Route path={'/group-messages/send'} element={<SendGroupMessage />} />

      {/* <Route path={'/replies'} element={<RepliesPage />} /> */}
      {/* <Route path={'/replies/add'} element={<AddEditReplyPage />} /> */}
      {/* <Route path={'/replies/edit'} element={<AddEditReplyPage />} /> */}

      <Route path={'/conn-whatsapp'} element={<CreateWhatsappConnection />} />
      <Route path={'/whatsapp'} element={<WhatsApp />} />

    </Routes>
  );
}

export default App;
