import { gettingCategoriesFiled, gettingCategoriesStart, gettingCategoriesSuccess } from "../slices/categoriesSlice";
import { deleteCategoryFiled, deleteCategoryStart, deleteCategorySuccess } from "../slices/deleteSlices/deleteCategorySlice";
import { addEditCategoryFiled, addEditCategoryStart, addEditCategorySuccess } from "../slices/formsSlices/addEditCategoryFormSlice";
import { axiosService } from "./axios.service";


const getAll = async (dispatch) => {
  try {
    dispatch(gettingCategoriesStart());
    const res = await axiosService.get('clients-categories/getAll');
    if (res.status === 200) {
      const dataRes = []
      res.data.data.forEach(element => {
        const category = {
          id: element.id,
          name: element.name,
          color: element.color,
          image: element.image,
        }
        dataRes.push(category);
      });
      dispatch(gettingCategoriesSuccess(dataRes))
    } else {
      dispatch(gettingCategoriesFiled(res.error.message))
    }
  } catch (error) {
    dispatch(gettingCategoriesFiled())
  }
};

const add = async (dispatch, body) => {
  try {
    dispatch(addEditCategoryStart())
    const res = await axiosService.post('clients-categories/add', body, true);
    if (res.status === 200) {
      dispatch(addEditCategorySuccess());
      return true
    } else {
      dispatch(addEditCategoryFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(addEditCategoryFiled());
    return false
  }
};

const edit = async (dispatch, body, clientCategory) => {
  try {
    dispatch(addEditCategoryStart())
    const res = await axiosService.post(`clients-categories/edit?clientCategory=${clientCategory}`, body);
    if (res.status === 200) {
      dispatch(addEditCategorySuccess());
      return true
    } else {
      dispatch(addEditCategoryFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(addEditCategoryFiled());
    return false
  }
};

const remove = async (dispatch, clientCategory) => {
  try {
    dispatch(deleteCategoryStart(clientCategory))
    const res = await axiosService.get(`clients-categories/delete?clientCategory=${clientCategory}`);
    if (res.status === 200) {
      dispatch(deleteCategorySuccess());
      return true
    } else {
      dispatch(deleteCategoryFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(deleteCategoryFiled());
    return false
  }
};


export const clientsCategoriesService = {
  getAll, add, edit, remove
}