import { createSlice } from '@reduxjs/toolkit';

const deleteReplySlice = createSlice({
    name: 'deleteReply',
    initialState: {
        isLoadingDeleteReply: false,
        deleteReplyError: null,
        deleteReplyId: null
    },
    reducers: {
        deleteReplyStart: (state, actions) => {
            state.isLoadingDeleteReply = true;
            state.deleteReplyError = null;
            state.deleteReplyId = actions.payload
        },
        deleteReplySuccess: (state) => {
            state.isLoadingDeleteReply = false;
            state.deleteReplyId = null
        },
        deleteReplyFiled: (state, actions) => {
            state.isLoadingDeleteReply = false;
            state.deleteReplyError = actions.payload;
            state.deleteReplyId = null
        },
    },
});

export const { deleteReplyStart, deleteReplySuccess, deleteReplyFiled } = deleteReplySlice.actions;
export default deleteReplySlice.reducer;
