import { createSlice } from '@reduxjs/toolkit';

const countriesSlice = createSlice({
    name: 'countries',
    initialState: {
        isLoadingCountries: false,
        countries: [],
        countriesError: null
    },
    reducers: {
        gettingCountriesStart: (state) => {
            state.isLoadingCountries = true;
            state.countries = [];
            state.countriesError = null;
        },
        gettingCountriesSuccess: (state, actions) => {
            state.isLoadingCountries = false;
            state.countries = actions.payload;
            state.countriesError = null;
        },
        gettingCountriesFiled: (state, actions) => {
            state.isLoadingCountries = false;
            state.countries = [];
            state.countriesError = actions.payload;
        }
    },
});

export const { gettingCountriesStart, gettingCountriesSuccess, gettingCountriesFiled } = countriesSlice.actions;
export default countriesSlice.reducer;
