import { createSlice } from '@reduxjs/toolkit';

const deleteCountrySlice = createSlice({
    name: 'deleteCountry',
    initialState: {
        isLoadingDeleteCountry: false,
        deleteCountryError: null,
        deleteCountryId: null
    },
    reducers: {
        deleteCountryStart: (state, actions) => {
            state.isLoadingDeleteCountry = true;
            state.deleteCountryError = null;
            state.deleteCountryId = actions.payload
        },
        deleteCountrySuccess: (state) => {
            state.isLoadingDeleteCountry = false;
            state.deleteCountryId = null
        },
        deleteCountryFiled: (state, actions) => {
            state.isLoadingDeleteCountry = false;
            state.deleteCountryError = actions.payload;
            state.deleteCountryId = null
        },
    },
});

export const { deleteCountryStart, deleteCountrySuccess, deleteCountryFiled } = deleteCountrySlice.actions;
export default deleteCountrySlice.reducer;
