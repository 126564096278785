import styled from "styled-components";
export const Styles = styled.div`
  .side-bar {
    display: flex;
    height: 100vh;
    overflow: scroll initial;
    & .pro-sidebar {
      background-color: var(--main-color);
      & .head-div.toggled {
        padding: 27.2px 30px;
      }
      & .logo {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fff !important;
        font-weight: normal;
        & .img-container {
          width: 35px;
          height: 100%;
          & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          & .icon-suffix {
            color: #fff;
          }
        }
      }

      & .sidebar-content {
        & div {
          display: flex;
          align-items: center;
          color: #fff;
          & span {
            text-align: start;
            margin-inline-start: 10px;
          }
          & .side-icon {
            color: #fff;
            margin: 0;
          }
        }
        & .toggled .toggled {
          padding-block: 8px;
          padding-inline-start: 0;
          padding-inline-end: 6px;
        }
      }
      & .sidebar-content.sidebar-content-en {
        & .toggled .toggled {
          padding-inline-start: 6px;
          padding-inline-end: 0;
        }
      }
      & .company {
        padding: 20px 5px;
        font-weight: bold;
      }
    }
  }
`;
