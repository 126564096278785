/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { CDBTableBody, CDBTableHeader, CDBTable } from 'cdbreact';
import withGuard from '../../helpers/withGuard';
import { ButtonGroup, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import MainButton from '../../components/buttons/mainButton/MainButton';
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading';
import DeleteIconButton from '../../components/buttons/iconButtons/DeleteIconButton';
import EditIconButton from '../../components/buttons/iconButtons/EditIconButton';
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton';
import MainBadge from '../../components/badges/mainBadge/MainBadge';
import { countriesService } from '../../services/countries.service';
import { setCountry } from '../../slices/formsSlices/addEditCountryFormSlice';
import { toasts } from '../../components/toasts';

function CountriesPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoadingCountries, countries, countriesError } = useSelector((state) => state.countries);
    const { isLoadingDeleteCountry, deleteCountryError, deleteCountryId } = useSelector((state) => state.deleteCountry);


    useEffect(() => {
        dispatch(setCountry(null));
        countriesService.getAll(dispatch)
    }, []);

    const editBtn = (country) => {
        dispatch(setCountry(country));
        navigate('edit');
    }


    const deleteBtn = async (country) => {
        const res = await countriesService.remove(dispatch, country.id)
        if (res) {
            toasts.success("تمت حذف البلدة بنجاح");
            await countriesService.getAll(dispatch)
        } else {
            toasts.error(deleteCountryError);
        }
    }

    return (
        <DefaultLayout title={'البلدات'}>
            <Card className='p-4'>
                <div className='my-3 d-flex justify-content-between'>
                    <MainBadge text={`${countries.length} بلدة`} />
                    <Link to={'add'}>
                        <MainButton text={'اضافة جديد'} />
                    </Link>
                </div>
                {isLoadingCountries ?
                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <SpinnerLoading />
                    </div> : countriesError ?
                        <div className='d-flex justify-content-center align-items-center py-5'>
                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await countriesService.getAll()} />
                        </div>
                        : <CDBTable hover responsive striped bordered>
                            <CDBTableHeader>
                                <tr className={'text-center'}>
                                    <th>#</th>
                                    <th>الاسم</th>
                                    <th>الإجراءات</th>
                                </tr>
                            </CDBTableHeader>
                            <CDBTableBody>
                                {countries.length > 0 ?
                                    Array.from(countries).map((value, index) => (
                                        <tr key={index} className={'text-center'}>
                                            <td>{++index}</td>
                                            <td>{value.name}</td>
                                            <td>
                                                <ButtonGroup >
                                                    <DeleteIconButton onClick={() => deleteBtn(value)} disable={isLoadingDeleteCountry && value.id === deleteCountryId} isLoading={isLoadingDeleteCountry && value.id === deleteCountryId} />
                                                    <EditIconButton onClick={() => editBtn(value)} />
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className='text-center text-danger' colSpan={5}>لا يوجد بيانات</td>
                                    </tr>
                                }
                            </CDBTableBody>
                        </CDBTable>
                }
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(CountriesPage);