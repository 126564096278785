import React from 'react'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { Card, FloatingLabel, Form as BootstrapForm, Button } from 'react-bootstrap'
import withGuard from '../../helpers/withGuard';
import { useDispatch, useSelector } from 'react-redux';
import * as formik from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { clientsCategoriesService } from '../../services/clientsCategories.service';
import { addCategoryFiled, addCategoryStart, addCategorySuccess } from '../../slices/formsSlices/addEditCategoryFormSlice';
import BtnLoading from '../../components/loading/btnLoading/BtnLoading';
import MainButton from '../../components/buttons/mainButton/MainButton';
import { toasts } from '../../components/toasts';
import { handelErrorsService } from '../../services/handelErrors.service';
import { addEditCountryFiled, addEditCountryStart, addEditCountrySuccess, setCountry } from '../../slices/formsSlices/addEditCountryFormSlice';
import { countriesService } from '../../services/countries.service';

const validationSchema = Yup.object().shape({
    name: Yup.string().required("اسم البلدة مطلوب"),
});

function AddEditCountryPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Formik } = formik;
    const { isLoadingAddEditCountry, addEditCountryError, country } = useSelector((state) => state.addEditCountryForm);


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            let res = null
            dispatch(addEditCountryStart())
            if (country != null) {
                res = await countriesService.edit(values, country.id);
            } else {
                res = await countriesService.add(values);
            }
            if (res.status === 200) {
                dispatch(addEditCountrySuccess());
                navigate('/countries');
                if (country != null) {
                    toasts.success("تم تحديث بيانات البلدة جديدة");
                } else {
                    toasts.success("تمت إضافة بلدة جديدة");
                }
            } else {
                dispatch(addEditCountryFiled(res.error.message));
                toasts.error(addEditCountryError);
            }
        } catch (error) {
            dispatch(addEditCountryFiled());
            toasts.error(addEditCountryError);
        } finally {
            setCountry(null)
            setSubmitting(false);
            dispatch(setCountry(null));
        }
    };

    return (
        <DefaultLayout title={'إضافة بلدة جديدة'}>
            <Card className='p-4'>
                <Formik initialValues={{ name: country != null ? country.name : "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ handleChange, values, touched, errors, isSubmitting }) => (
                        <formik.Form>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <FloatingLabel controlId="name" label="البلدة" className={'mb-3'}>
                                        <BootstrapForm.Control type="text" name="name" placeholder="مثال:عملاء مميزين" value={values.name}
                                            onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && errors.name} />
                                        <BootstrapForm.Control.Feedback type="invalid">
                                            {errors.name}
                                        </BootstrapForm.Control.Feedback>
                                    </FloatingLabel>
                                </div>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-end'>
                                <MainButton type="submit" disabled={isSubmitting} text={isLoadingAddEditCountry ? <BtnLoading /> : country == null ? "إضافة" : "تحديث"} />
                            </div>
                        </formik.Form>
                    )}
                </Formik>
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(AddEditCountryPage)