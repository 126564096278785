import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    connected: false,
    sendData: null,
    messages: [],
};

const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        receiveData: (state, action) => {
            state.messages.push(action.payload);
        },
        open: (state, action) => {
            state.connected = true;
            state.sendData = action.payload;
        },
        close: (state) => {
            state.connected = false;
            state.sendData = null;
        },
        clearMessages: (state) => {
            state.messages = [];
        },
    },
});

export const { receiveData, open, close, clearMessages } = websocketSlice.actions;
export default websocketSlice.reducer;
