import { createSlice } from '@reduxjs/toolkit';

const registerFormSlice = createSlice({
    name: 'registerForm',
    initialState: {
        isLoadingRegister: false,
        registerError: null,
        countryCode: 0
    },
    reducers: {
        registerStart: (state) => {
            state.isLoadingRegister = true;
            state.registerError = null;
        },
        registerSuccess: (state) => {
            state.isLoadingRegister = false;
        },
        registerFiled: (state, actions) => {
            state.isLoadingRegister = false;
            state.registerError = actions.payload;
        },
        setCountryCode: (state, { payload }) => {
            state.countryCode = payload
        }
    },
});

export const { registerStart, registerSuccess, registerFiled, setCountryCode } = registerFormSlice.actions;
export default registerFormSlice.reducer;
