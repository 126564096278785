import { createSlice } from '@reduxjs/toolkit';

const addEditCategoryFormSlice = createSlice({
    name: 'addEditCategoryForm',
    initialState: {
        isLoadingAddEditCategory: false,
        addEditCategoryError: null,
        category: null,
    },
    reducers: {
        addEditCategoryStart: (state) => {
            state.isLoadingAddEditCategory = true;
            state.addEditCategoryError = null;
        },
        addEditCategorySuccess: (state) => {
            state.isLoadingAddEditCategory = false;
        },
        addEditCategoryFiled: (state, actions) => {
            state.isLoadingAddEditCategory = false;
            state.addEditCategoryError = actions.payload;
        },
        setCategory: (state, actions) => {
            state.isLoadingAddEditCategory = false;
            state.addEditCategoryError = null;
            state.category = actions.payload
        },
    },
});

export const { addEditCategoryStart, addEditCategorySuccess, addEditCategoryFiled, setCategory } = addEditCategoryFormSlice.actions;
export default addEditCategoryFormSlice.reducer;
