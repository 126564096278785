import { createSlice } from '@reduxjs/toolkit';

const templatesSlice = createSlice({
    name: 'templates',
    initialState: {
        isLoading: false,
        templates: [],
        error: null
    },
    reducers: {
        gettingTemplatesStart: (state) => {
            state.isLoading = true;
            state.templates = [];
            state.error = null;
        },
        gettingTemplatesSuccess: (state, actions) => {
            state.isLoading = false;
            state.templates = actions.payload;
            state.error = null;
        },
        gettingTemplatesFiled: (state, actions) => {
            state.isLoading = false;
            state.templates = [];
            state.error = actions.payload;
        }
    },
});

export const { gettingTemplatesStart, gettingTemplatesSuccess, gettingTemplatesFiled } = templatesSlice.actions;
export default templatesSlice.reducer;
