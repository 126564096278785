import styled from "styled-components";
export const Styles = styled.div`
 ${'' /* Public */}
.main-page{
    display: flex;
 }
 .main-data{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .children-data{
    padding:20px 0;
    height: 100%;
    overflow: auto;
 }

 ${'' /* Navbar */}
 .navbar{
    padding-block: 19px;
    background-color: var(--main-color);
    border-bottom: 1px solid var(--light-color);
 }
 .navbar .navbar-brand{
    margin-inline-start: 0;
 }
 .navbar .navbar-nav{
   flex-direction: row;
   align-items: center;
 }
 .dropdown {
    position: relative;
 }
 .dropdown-toggle#profile {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
 }
 .dropdown-toggle#profile .img-container{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
 }
 .dropdown-toggle#profile .img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
 }
 .dropdown .dropdown-menu {
    position: absolute;
    left: 50%;
    transform:translateX(-50%);
    top: 130%;
    background-color: var(--main-color);
 }
 .dropdown .dropdown-menu-ar{
   left: auto;
   transform:translateX(10%);
 }
 .dropdown-menu a{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .dropdown-menu .dropdown-item:hover{
    background-color: transparent;
  opacity: 0.8;
 }


.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl{
   justify-content:end
}
 ${'' /* Sidebar */}
 .sidebar{
   height:100vh
 }

 ${'' /* Footer */}
 footer {
    position: relative;
    padding-block: 10px;
    box-shadow: 0px 0px 10px var(--dark-purple);
    background-color: var(--light-color);
    font-size: 14px;
  }
  footer .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer a{
    color: var(--main-color) !important;
    font-weight: bold;
  }

  ${'' /* Public */}
   ${'' /* .btn-primary {
    color: #fff !important;
    border: 1px solid var(--main-color);
    font-size: 14px;
    font-weight: 900;
    background: var(--main-color);
    cursor: pointer;
    transition: 0.3s;
  }
  .btn-primary:hover {
    background: transparent;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color);
  }
  .btn-primary.disabled {
    background: #ccc;
    color: var(--secondary-color);
    border: 1px solid #ccc;
  } */}
`;
