import React from 'react'
import { Button } from 'react-bootstrap'
import { Styles } from './styles'

export default function TextIconButton({ onClick, text, type = "button", iconsNum, disabled = false, variant }) {
  const icons = ['refresh'];
  return (
    <Styles>
      <Button className='btn rounded text-center py-2 px-3' variant={variant} onClick={onClick} type={type} disabled={disabled}>
        <span className='mx-3'>{text}</span>
        <i className={'fa fa-' + icons[iconsNum]}></i>
      </Button>
    </Styles>
  )
}
