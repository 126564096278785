import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/styles/index.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import WebSocket from "./components/WebSocket";


const Main = () => {
  const isLoggedIn = store.getState().auth.isLoggedIn

  return (
    <React.Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer />
          <App />
          {isLoggedIn ? (
            <WebSocket />
          ) : <></>}
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Main />);
