import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import Logo from "../../assets/images/white_logo.webp";
import { Link } from "react-router-dom";
import TooltipComponent from "../TooltipComponent";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isSidebarOpen) {
      document
        .getElementsByClassName("pro-sidebar")[0]
        ?.classList?.add("toggled");
      document.getElementsByClassName("head-div")[0]?.classList?.add("toggled");
    } else {
      document
        .getElementsByClassName("pro-sidebar")[0]
        ?.classList?.remove("toggled");
      document
        .getElementsByClassName("head-div")[0]
        ?.classList?.remove("toggled");
    }
  }, [isSidebarOpen])

  return (
    <Styles>
      <div className="side-bar">
        <CDBSidebar>
          <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
            <Link to="/" className="logo">
              <div className="img-container">
                <img src={Logo} alt="logo" />
              </div>{" "}
              Akwad
            </Link>
          </CDBSidebarHeader>
          <CDBSidebarContent
            className={"sidebar-content"}>
            <CDBSidebarMenu>
              {/* <Link to="/">
                <CDBSidebarMenuItem icon="th-large">
                  لوحة التحكم
                </CDBSidebarMenuItem>
              </Link> */}
              <Link to="/clients">
                <CDBSidebarMenuItem icon="users">العملاء</CDBSidebarMenuItem>
              </Link>
              <Link to="/categories" >
                <CDBSidebarMenuItem icon="cubes">أصناف العملاء</CDBSidebarMenuItem>
              </Link>
              <Link to="/countries" >
                <CDBSidebarMenuItem icon="city">البلدات</CDBSidebarMenuItem>
              </Link>
              {/* <Link to="/replies"> */}
              {/* <CDBSidebarMenuItem icon="comment">الردود</CDBSidebarMenuItem> */}
              {/* </Link> */}
              {/* <TooltipComponent text={'فقط في النسخة المدفوعة'}> */}
              <Link to="/group-messages">
                <CDBSidebarMenuItem icon="comments" >الرسائل الجماعية</CDBSidebarMenuItem>
              </Link>
              {/* </TooltipComponent> */}
              <Link to="/conn-whatsapp">
                <CDBSidebarMenuItem icon="comment">الاتصال بالواتساب</CDBSidebarMenuItem>
              </Link>
            </CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      </div>
    </Styles>
  );
};

export default Sidebar;
