/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import withGuard from '../../helpers/withGuard'
import DefaultLayout from '../layouts/defaultLayout/DefaultLayout'
import { Card, Table } from 'react-bootstrap'
import TextIconButton from '../../components/buttons/textIconButton/TextIconButton'
import SpinnerLoading from '../../components/loading/spinnerLoading/SpinnerLoading'
import MainButton from '../../components/buttons/mainButton/MainButton'
import { Link } from 'react-router-dom'
import MainBadge from '../../components/badges/mainBadge/MainBadge'
import { useDispatch, useSelector } from 'react-redux'
import { messagesService } from '../../services/messages.service'
import { toasts } from '../../components/toasts'
import Paginator from '../../components/pagination/Paginator'

function GroupMessagesPage() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState(0);
    const { isLoadingGroupMessages, groupMessages, groupMessagesError } = useSelector((state) => state.groupMessages);


    useEffect(() => {
        getGroupMessages();
    }, []);

    async function getGroupMessages(pageNumber) {
        const res = await messagesService.getAll(dispatch, pageNumber);
        if (res.success) {
            setCurrentPage(res.currentPage)
            setItemsCount(res.itemsCount)
        } else {
            toasts.error(groupMessagesError)
        }
    }

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        await getGroupMessages(pageNumber)
    };


    return (
        <DefaultLayout title={'الرسائل الجماعية'}>
            <Card className='p-4'>
                <div className='my-3 d-flex justify-content-between'>
                    <MainBadge text={`${groupMessages.length} سجل`} />
                    <Link to={'send'}>
                        <MainButton text='ارسال رسالة جديدة' />
                    </Link>
                </div>
                {isLoadingGroupMessages ?
                    <div className='d-flex justify-content-center align-items-center py-5'>
                        <SpinnerLoading />
                    </div> : groupMessagesError ?
                        <div className='d-flex justify-content-center align-items-center py-5'>
                            <TextIconButton text={"اعادة المحاولة"} iconsNum={0} variant={'danger'} onClick={async () => await getGroupMessages()} />
                        </div> :
                        <Table hover responsive striped bordered>
                            <thead>
                                <tr className={'text-center'}>
                                    <th>#</th>
                                    <th>القالب</th>
                                    <th>عدد المرسل لهم</th>
                                    {/* <th>ملف الأرقام</th> */}
                                    <th>تاريخ الارسال</th>
                                    {/* <th>الإجراءات</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {groupMessages.length > 0 ?
                                    Array.from(groupMessages).map((value, key) => (
                                        <tr key={key} className={'text-center'}>
                                            <td>{++key}</td>
                                            <td>{value.template}</td>
                                            <td>{value.numbersCount}</td>
                                            {/* <td>{value.numbersFile || "لا يوجد"}</td> */}
                                            <td>{value.createdAt}</td>
                                            {/* <td>
                                                <ButtonGroup >
                                                    <DeleteIconButton />
                                                    <EditIconButton onClick={() => editBtn(value)} />
                                                </ButtonGroup>
                                            </td> */}
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td className='text-center text-danger' colSpan={9}>لا يوجد بيانات</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                }
                <Paginator
                    totalItems={itemsCount}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </Card>
        </DefaultLayout>
    )
}

export default withGuard(GroupMessagesPage)