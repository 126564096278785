import { helperFunctions } from "../helpers/helperFunctions";
import { sendGroupMessageFiled, sendGroupMessageStart, sendGroupMessageSuccess } from "../slices/formsSlices/sendGroupMessageFormSlice";
import { gettingGroupMessagesFiled, gettingGroupMessagesStart, gettingGroupMessagesSuccess } from "../slices/groupMessagesSlice";
import { axiosService } from "./axios.service";


const getAll = async (dispatch, pageNumber) => {
  const dataRes = []
  try {
    dispatch(gettingGroupMessagesStart());
    const res = await axiosService.get(`messages/get-all-group?page=${pageNumber}`)
    if (res.status === 200) {
      res.data.data.data.forEach(element => {
        const groupMessage = {
          id: element.id,
          template: element.template_id,
          createdAt: helperFunctions.formatDate(element?.created_at),
          numbersCount: element.numbers_count,
          numbersFile: element?.numbers_file,
        }
        dataRes.push(groupMessage);
      });
      const result = {
        success: true,
        currentPage: res.data.data.current_page,
        itemsCount: res.data.data.total,
      };

      dispatch(gettingGroupMessagesSuccess(dataRes))
      return result
    } else {
      const result = {
        success: false,
        currentPage: null,
        itemsCount: null,
      };

      dispatch(gettingGroupMessagesFiled(res.error.message))
      return result;
    }
  } catch (error) {
    const result = {
      success: false,
      currentPage: null,
      itemsCount: null,
    };
    dispatch(gettingGroupMessagesFiled(error?.message || "An error occurred"))
    return result;
  }
};

const sendGroup = async (dispatch, body) => {
  try {
    dispatch(sendGroupMessageStart())
    const res = await axiosService.post('messages/send-group', body, true);

    if (res.status === 200) {
      dispatch(sendGroupMessageSuccess(res.data.message));
      return true
    } else {
      dispatch(sendGroupMessageFiled(res.error.message));
      return false
    }
  } catch {
    dispatch(sendGroupMessageFiled());
    return false
  }
};

const send = async (body) => {
  const res = await axiosService.post('messages/send', body);
  return res;
};


export const messagesService = {
  sendGroup, send, getAll
}