import React from 'react'
import { Button } from 'react-bootstrap'
import { Styles } from './styles'

export default function EditIconButton({ onClick }) {
  return (
    <Styles>
      <Button className='btn mx-2 rounded edit-icon-btn' onClick={onClick}><i className='fa fa-edit'></i></Button>
    </Styles>
  )
}
