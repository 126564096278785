
import React from 'react'
import image from '../../assets/images/avatar.jpg'


export default function HomePage() {

    return (
      <>
        <style>
  {`
    .icon{
      font-size:30px;
      width:50px;
      display:inline-block;
      text-align:center;
      
    }
    p{
      direction:rtl;
      width:fit-content;
      padding:10px;
      display:block;
      background-color: #a2a1a1;
      border-radius: 15px;
    
    }
    .p-section{
      display: flex;
      height: calc(100vh - 120px);
      flex-direction: column;
      overflow-y: scroll;
      padding-block: 10px;
      box-shadow:0px 0px 4px 0px inset #797070;
    }
    
    span{
      display: block;
      width: calc(100% - 200px);
      text-align: end;
    }
    .width{
      min-width: calc(100% - 60px);
      text-align: start;
      justify-content: space-between;
      background-color: gray;
      border-radius: 25px;
      height: 50px;
    }
  .height{
    height:60px;
    background-color: gray;
    color: white;
  
  }
  .circle{
    border-radius:50%;
    background-color:green;
    height:50px;
    min-width:50px;
    margin:5px;
    color:white
  }
  input, input:focus, input:active, input:target {
    width: calc(100% - 150px);
    background-color: transparent;
    border: none;
    color: white;
  }
  `}
</style>
<div className='w-100 m-auto border' style={{maxWidth:'600px'}}>

          <div className='d-flex justify-content-space-between align-items-center height'  >
            <div>
              <i className='icon'>:</i>
              <i className='icon'>/</i>
            </div>
            
            <span className='' >امجد الشاه</span>
            <div  className='d-flex'>
              <div className='d-flex m-1 justify-content-center  rounded-circle align-align-items-center text-center text-muted bg-light of-hidden align-self-center'>
                <img className=' rounded-circle' width='40px' height='40px' alt='image' src={image} />
              </div>
              <i className='icon'>x</i>
            </div>
            
          </div>
          <div className='p-section'>
            <p className='align-self-end ms-5'>text text text text text text text text text</p>
            <p className='align-self-start me-5'>text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text text text text</p>
            <p className='align-self-start me-5'>text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text text text text</p>
            <p className='align-self-start me-5'>text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text</p>
            <p className='align-self-start me-5' >text text </p>
            <p className='align-self-start me-5'>text text </p>
            <p className='align-self-end  ms-5'>text text </p>
            <p className='align-self-end  ms-5'>text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text text text text</p>
            <p className='align-self-start me-5'>text text text</p>
            <p className='align-self-end  ms-5'>text text text text text text</p>
            <p className='align-self-end  ms-5'>text text </p>
            <p className='align-self-end  ms-5'>text text </p>
            <p className='align-self-start me-5'>text text </p>
            
          </div>
          <div className='d-flex h-3 justify-content-space-between align-items-center height' style={{backgroundColor:'transparent'}}>
            <i className='icon circle'>s</i>
            <div className='d-flex h-3 align-items-center width' >
              <div className=''>
                <i className='icon'>g</i>
                <i className='icon'>f</i>
              </div>
              <input className='' placeholder='مراسلة' dir='rtl' />
              <i className='icon '>w</i>
            </div>
          </div>
          
          
        </div>
      </>

        
    )
}
