import axios from "axios";
import environment from "../environment";
import store from "../store"

const instanceAxios = axios.create({
  baseURL: environment.API_URL,
  headers: {
    'Authorization': `Bearer ${store.getState().auth.token}`,
    'Content-Type': 'application/json',
  },
});



const get = async (url) => {
  let res = {};
  await instanceAxios.get(url)
    .then((response) => {
      res.status = response.status;
      res.data = response.data;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made, but the server responded with an error status code
        console.error("Server returned an error:", error.response.data);
        res.status = error.response.status;
        res.error = error.response.data;
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received from the server");
        res.status = 400;
        res.error = 'خطأ في ارسال البيانات';
      } else {
        // Something else went wrong
        console.error("Error:", error.message);
        res.status = 501;
        res.error = 'خطأ في الإتصال';
      }
    });
  return res;
};

const post = async (url, body, isMultiPart = false) => {
  let res = {};

  if (isMultiPart)
    instanceAxios.defaults.headers['Content-Type'] = "multipart/form-data";

  await instanceAxios
    .post(url, body)
    .then((response) => {
      // console.log(response.status)
      // console.log(response.data)
      res.status = response.status;
      res.data = response.data;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made, but the server responded with an error status code
        console.error("Server returned an error:", error.response.data);
        res.status = error.response.status;
        res.error = error.response.data;
      } else if (error.request) {
        console.log('====================================');
        console.log(error);
        console.log('====================================');
        // The request was made, but no response was received
        console.error("No response received from the server");
        res.status = 400;
        res.error = 'خطأ في ارسال البيانات';
      } else {
        // Something else went wrong
        console.error("Error:", error.message);
        res.status = 501;
        res.error = 'خطأ في الإتصال';
      }
    });
  return res;
};


function setToken(token) {
  instanceAxios.defaults.headers['Authorization'] = `Bearer ${token}`;
}


export const axiosService = {
  get, post, setToken
}